import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { DateRangePicker } from "rsuite";
import { getTransactions } from "../../../actions/transactions";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "transactionDate",
    numeric: false,
    disablePadding: true,
    label: "Transaction Date",
  },
  {
    id: "merchantName",
    numeric: false,
    disablePadding: true,
    label: "Merchant Name",
  },
  {
    id: "merchantEmail",
    numeric: false,
    disablePadding: true,
    label: "Merchant Email",
  },
  {
    id: "donatedAmount",
    numeric: false,
    disablePadding: false,
    label: "Donated Amount",
  },
  {
    id: "cartValue",
    numeric: false,
    disablePadding: false,
    label: "User's Cart Value",
  },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "center"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "40px",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableHead: {
    textAlign: "center",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

export const Transactions = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [transactions, setTransaction] = useState([]);
  const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });
  const authRole = useSelector((state) => state.auth.authenticatedRole);

  const { afterToday } = DateRangePicker;

  useEffect(() => {
    dispatch(
      getTransactions(authRole, dateRange, (data) => {
        setTransaction(data);
      })
    );
    return () => {};
  }, [dateRange, authRole]);

  const closeDateRangePicker = () => {
    dispatch(
      getTransactions(authRole, {}, (data) => {
        setTransaction(data);
      })
    );
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const selectedDate = (value) => {
    if (Array.isArray(value)) {
      const startDate = new Date(value[0]).toISOString();
      const endDate = new Date(value[1]).toISOString();
      setDateRange({ startDate, endDate });
    }
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, transactions.length - page * rowsPerPage);

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className={classes.root}>
            <Paper className={classes.paper}>
              <Toolbar className={clsx(classes.root)}>
                <Typography
                  className={classes.title}
                  variant="h6"
                  id="tableTitle"
                  component="div"
                >
                  Transactions
                </Typography>
              </Toolbar>
              <Container maxWidth="lg" className={classes.container}>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <DateRangePicker
                      onOk={(value) => selectedDate(value)}
                      onClean={() => closeDateRangePicker()}
                      disabledDate={afterToday()}
                    />
                  </Grid>
                </Grid>
              </Container>
              <TableContainer>
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={"medium"}
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    classes={classes.tableHead}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={transactions.length}
                  />
                  <TableBody>
                    {stableSort(transactions, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={row.id}
                            tabIndex={-1}
                            key={index}
                          >
                            <TableCell padding="checkbox"></TableCell>
                            <TableCell align="center">
                              {row.date
                                ? row.date.substr(0, 10) +
                                  "  -  " +
                                  new Date(row.date).toLocaleTimeString("en-US")
                                : "NA"}
                            </TableCell>
                            {row.user.length > 0 ? (
                              <>
                                <TableCell align="center">
                                  {row.user[0].companyName}
                                </TableCell>
                                <TableCell align="center">
                                  {row.user[0].storeName}
                                </TableCell>
                              </>
                            ) : (
                              ""
                            )}

                            <TableCell align="center">
                              {row.currency}
                              {row.donationAmount}
                            </TableCell>
                            <TableCell align="center">
                              {row.currency}
                              {row.totalAmount}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={transactions.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Paper>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};
