import React from "react";
import TextField from "@material-ui/core/TextField";
import { useDispatch } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { searchCharity } from "../../../../actions/charity";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { getDonationByCharity } from "../../../../actions/transactions";

export default function AsyncAutoComplete({ setCharities }) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const dispatch = useDispatch();
  const loading = open && options.length === 0;

  const onChangeHandle = React.useCallback(
    AwesomeDebouncePromise(async (value) => {
      // use the changed value to make request and then use the result. Which
      dispatch(
        searchCharity(value, (charities = []) => {
          setOptions(charities || []);
        })
      );
    }, 500),
    []
  );

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id="asynchronous-demo"
      style={{ width: 400, float: "right" }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => {
        if (option.charityName === value.charityName) {
          dispatch(
            getDonationByCharity(option._id, undefined, (response) => {
              setCharities({
                ...option,
                ...response,
              });
            })
          );
        }

        return option.charityName === value.name;
      }}
      getOptionLabel={(option) => option.charityName}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search Charity"
          variant="outlined"
          onChange={(ev) => {
            if (ev.target.value !== "" || ev.target.value !== null) {
              onChangeHandle(ev.target.value);
            }
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
