import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { useDispatch } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Success from "./Success";
import * as actions from "../../../../actions/auth";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: 16,
    minWidth: 270,
  },
}));

// Destructuring props
const FirstStep = ({
  handleChange,
  values: {
    companyName,
    storeName,
    companyWebsite,
    companyEmail,
    productCategory,
    otherCategoryName,
  },
  formErrors,
}) => {
  const classes = useStyles();
  const [isSuccess, setIsSuccess] = useState(false);
  const dispatch = useDispatch();

  const handleNext = () => {
    let body = {};
    body.companyEmail = companyEmail;
    body.companyName = companyName;
    body.storeName = storeName;
    body.companyWebsite = companyWebsite;
    if (productCategory === "otherCategories") {
      body.productCategory = otherCategoryName;
    } else {
      body.productCategory = productCategory;
    }
    dispatch(
      actions.signUp(body, (status, message) => {
        if (status == "success") {
          setIsSuccess(true);
        }
      })
    );
  };
  // Check if all values are not empty or if there are some error
  const isValid =
    companyName.length > 0 &&
    !formErrors.companyName &&
    storeName.length > 0 &&
    !formErrors.storeName &&
    companyWebsite.length &&
    !formErrors.companyWebsite &&
    companyEmail.length > 0 &&
    !formErrors.companyEmail &&
    productCategory.length > 0 &&
    !formErrors.productCategory;

  return (
    <Fragment>
      {!isSuccess ? (
        <>
          <Grid container spacing={2} noValidate>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                variant="outlined"
                label="Company Name"
                name="companyName"
                placeholder="Enter Company Name"
                margin="normal"
                value={companyName || ""}
                onChange={handleChange}
                error={!!formErrors.companyName}
                helperText={formErrors.companyName}
                autoFocus
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                fullWidth
                label="Store Name"
                name="storeName"
                placeholder="Store Name"
                margin="normal"
                value={storeName || ""}
                onChange={handleChange}
                error={!!formErrors.storeName}
                helperText={formErrors.storeName}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                fullWidth
                label="Company Email"
                name="companyEmail"
                placeholder="Company Email"
                value={companyEmail || ""}
                onChange={handleChange}
                error={!!formErrors.companyEmail}
                helperText={formErrors.companyEmail}
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                fullWidth
                label="Company Website"
                name="companyWebsite"
                placeholder="Company Website"
                value={companyWebsite || ""}
                onChange={handleChange}
                error={!!formErrors.companyWebsite}
                helperText={formErrors.companyWebsite}
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Product Category
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Product Category"
                  value={productCategory}
                  name="productCategory"
                  onChange={handleChange}
                >
                  <MenuItem value="apparelAccessories">
                    Apparel & Accessories
                  </MenuItem>
                  <MenuItem value="styleFashion">Style & Fashion</MenuItem>
                  <MenuItem value="homeGarden">Home & Garden</MenuItem>
                  <MenuItem value="sportingGoods">Sporting Goods</MenuItem>
                  <MenuItem value="healthWellness">Health & Wellness</MenuItem>
                  <MenuItem value="medicalHealth">Medical Health</MenuItem>
                  <MenuItem value="childrenInfants">
                    Children & Infants
                  </MenuItem>
                  <MenuItem value="petSupplies">Pets & Pet Supplies</MenuItem>
                  <MenuItem value="electronicGoods">
                    Consumer Electronic Goods
                  </MenuItem>
                  <MenuItem value="homeImprovement">Home Improvement</MenuItem>
                  <MenuItem value="otherCategories">Other Categories</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              {productCategory === "otherCategories" && (
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Other category"
                  name="otherCategoryName"
                  placeholder="Please fill the other category"
                  value={otherCategoryName || ""}
                  onChange={handleChange}
                  error={!!formErrors.otherCategoryName}
                  helperText={formErrors.otherCategoryName}
                  margin="normal"
                  required
                />
              )}
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              marginTop: 50,
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              disabled={!isValid}
              color="primary"
              onClick={isValid ? handleNext : null}
            >
              Submit
            </Button>
          </div>{" "}
        </>
      ) : (
        <Success successStage={"Approve"} />
      )}
    </Fragment>
  );
};

export default FirstStep;
