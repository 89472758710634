import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import { Tooltip } from "@material-ui/core";
import UpdateCharity from "./updateCharity";
import formValidation from "../../../Shared/utilities/formValidation";

const fieldsValidation = {
  charityName: {
    error: "",
    validate: "required",
  },
  charityWebsite: {
    error: "",
    validate: "required",
  },
  charityNumber: {
    error: "",
    validate: "number",
  },
  charityAddress: {
    error: "",
    validate: "required",
  },
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "merchantName",
    numeric: false,
    disablePadding: false,
    label: "Merchant Name",
  },
  {
    id: "charityName",
    numeric: false,
    disablePadding: false,
    label: "Charity Name",
  },
  {
    id: "website",
    numeric: false,
    disablePadding: false,
    label: "Website",
  },
  {
    id: "number",
    numeric: false,
    disablePadding: false,
    label: "Number",
  },
  {
    id: "address",
    numeric: false,
    disablePadding: false,
    label: "Address",
  },
  {
    id: "actions",
    numeric: true,
    disablePadding: false,
    label: "Actions",
  },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableHead: {
    textAlign: "center",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const initialFormValues = {
  charityName: "",
  charityWebsite: "",
  charityNumber: "",
  charityAddress: "",
};

export const CharityTable = ({
  rows,
  handleDelete,
  handleFormUpdate,
  handleApprove,
}) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [open, setOpen] = React.useState(false);
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [currentRow, setCurrentRow] = React.useState({});
  const [formErrors, setFormErrors] = React.useState({});
  const [formFields, setFormFiels] = React.useState(initialFormValues);

  const handleClose = (status) => {
    if (status) {
      handleDelete(currentRow._id);
    }
    setOpen(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleOpen = (row, operation) => {
    if (operation == "edit") {
      setOpenEditModal(true);
    }
    if (operation == "delete") {
      setOpen(true);
    }
    setCurrentRow(row);
  };

  const handleCharityForm = (e) => {
    const { name, value } = e.target;
    setCurrentRow((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
    const error = formValidation(name, value, fieldsValidation) || "";

    setFormErrors((prev) => ({
      ...prev,
      [name]: error,
    }));
  };

  const handleCancelCharityUpdate = () => {
    setOpenEditModal(false);
  };

  const handleCharityUpdate = () => {
    handleFormUpdate(currentRow);
    setOpenEditModal(false);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar className={clsx(classes.root)}>
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {!openEditModal ? "Charities" : "Add Charity"}
          </Typography>
          <Button
            variant="outlined"
            style={{ marginLeft: "8px" }}
            startIcon={<AddIcon />}
            onClick={() => handleOpen(initialFormValues, "edit")}
          >
            Add
          </Button>
        </Toolbar>
        {!openEditModal ? (
          <>
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={"medium"}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes.tableHead}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={row.id}
                          tabIndex={-1}
                          key={index}
                        >
                          <TableCell padding="checkbox"></TableCell>
                          <TableCell align="left">
                            {row.user[0].role === "User"
                              ? row.user[0].companyName
                              : "Admin"}
                          </TableCell>
                          <TableCell align="left">{row.charityName}</TableCell>
                          <TableCell align="left">
                            {row.charityWebsite}
                          </TableCell>
                          <TableCell align="left">
                            {row.charityNumber}
                          </TableCell>
                          <TableCell align="left">
                            {row.charityAddress}
                          </TableCell>
                          <TableCell align="center">
                            {!row.isApproved ? (
                              <>
                                <Button
                                  variant="contained"
                                  style={{ marginRight: "8px" }}
                                  onClick={() => handleOpen(row, "delete")}
                                >
                                  <DeleteIcon style={{ color: "red" }} />
                                </Button>

                                <Tooltip title="Approve this charity">
                                  <Button
                                    variant="contained"
                                    // color="action"
                                    onClick={() => handleApprove(row)}
                                  >
                                    <CheckIcon color="success" />
                                  </Button>
                                </Tooltip>
                              </>
                            ) : (
                              "-"
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </>
        ) : (
          <UpdateCharity
            formValues={currentRow}
            handleUpdate={handleCharityUpdate}
            handleChange={handleCharityForm}
            handleCancelUpdate={handleCancelCharityUpdate}
            formErrors={formErrors}
          />
        )}
      </Paper>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Change current status "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this charity ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)}>No</Button>
          <Button onClick={() => handleClose(true)} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
