import axios from "axios";
import { SERVER_URL } from "../../constants";

const attachHeaders = () => {
  const token = localStorage.getItem("token");
  const header = { authorization: token };
  return header;
};

export const getAdminSettings = (callBack) => async (dispatch) => {
  try {
    const header = attachHeaders();
    const { data } = await axios.get(`${SERVER_URL}adminSettings`, {
      headers: header,
    });
    const { status, response } = data;
    console.log(response, "response");
    callBack({ status, data: Array.isArray(response) ? response[0] : [] });
  } catch (e) {
    callBack({ status: "error", message: e.response.data.msg });
  }
};

export const saveAdminSettings = (adminData, callBack) => async (dispatch) => {
  try {
    const header = attachHeaders();
    const { data } = await axios.post(`${SERVER_URL}adminSettings`, adminData, {
      headers: header,
    });
    const { status, response } = data;
    console.log(data, "data");

    callBack({ status, message: "Settings have been added!" });
  } catch (e) {
    callBack({ status: "error", message: e.response.data.msg });
  }
};

export const updateAdminSettings =
  (adminData, callBack) => async (dispatch) => {
    try {
      const header = attachHeaders();
      const { data } = await axios.put(
        `${SERVER_URL}adminSettings`,
        adminData,
        {
          headers: header,
        }
      );
      const { status, response } = data;
      console.log(data, "data");

      callBack({ status, message: "Settings have been updated!" });
    } catch (e) {
      callBack({ status: "error", message: e.response.data.msg });
    }
  };
