import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fade, makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Grid from "@material-ui/core/Grid";
import CustomSnackbar from "../../../Shared/components/CustomSnackbar";
import { CharityTable } from "./charityTable";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import AsyncAutoComplete from "./AsyncAutoComplete";
import Link from "@material-ui/core/Link";
import Collapse from "@material-ui/core/Collapse";
import {
  deleteCharity,
  getAllCharities,
  updateCharity,
  approveCharity,
  submitManualCharityRequest,
} from "../../../../actions/charity";
import { useDebouncedSearch } from "../../../../Hooks/useDebounce";
import { getDonationByCharity } from "../../../../actions/transactions";
import { CharityTransactions } from "./CharityTransactions";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  grow: {
    flexGrow: 1,
  },
  grid: {
    width: "100%",
    padding: "16px",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
    backgroundColor: theme.palette.divider,
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export const Charities = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { message: messageObj } = useSelector((state) => state.message);
  const [checked, setChecked] = React.useState(false);
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    isError: "",
    message: "",
    handleClose: () => closeSnackbar(),
  });

  const [charities, setCharities] = useState({});
  const [charityArray, setCharityArray] = useState([]);
  const [charityTransactions, setCharityTransactions] = useState([]);

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar((preVal) => ({
      ...preVal,
      isOpen: false,
    }));
  };

  const sortCharity = (sortCharity) => {
    return sortCharity.sort((a, b) => a.isApproved - b.isApproved);
  };

  useEffect(() => {
    // console.log(messageObj);
    dispatch(
      getAllCharities(({ status, message }) => {
        if (status === "success") {
          setCharityArray(sortCharity(message));
        }
      })
    );
    return () => {};
  }, [messageObj]);

  const handleDelete = (_id) => {
    const body = {};
    body.id = _id;

    dispatch(
      deleteCharity(body, ({ status, message }) => {
        setSnackbar((prevValue) => {
          return { ...prevValue, isOpen: true, isError: status, message };
        });
      })
    );
  };
  const handleUpdate = (charity) => {
    const body = {};
    body.id = charity._id;
    body.charityName = charity.charityName || "";
    body.charityWebsite = charity.charityWebsite || "";
    body.charityNumber = charity.charityNumber || 0;
    dispatch(
      updateCharity(body, ({ status, message }) => {
        setSnackbar((prevValue) => {
          return { ...prevValue, isOpen: true, isError: status, message };
        });
      })
    );
  };

  const handleAddCharity = (charity) => {
    const body = {};
    body.id = charity._id;
    body.charityName = charity.charityName || "";
    body.charityWebsite = charity.charityWebsite || "";
    body.charityNumber = charity.charityNumber || 0;
    body.charityAddress = charity.charityAddress || "";
    body.byAdmin = true;
    dispatch(
      submitManualCharityRequest(body, ({ status, message }) => {
        setSnackbar((prevValue) => {
          return { ...prevValue, isOpen: true, isError: status, message };
        });
      })
    );
  };

  const handleApprove = (charity) => {
    let body = {};
    body.id = charity._id;
    body.isApproved = true;
    console.log(charity, "charity");
    dispatch(
      approveCharity(body, ({ status, message }) => {
        setSnackbar((prevValue) => {
          return { ...prevValue, isOpen: true, isError: status, message };
        });
      })
    );
  };

  const showTransactions = () => {
    dispatch(
      getDonationByCharity(charities._id, "showTransctions", (response) => {
        setChecked((prev) => !prev);
        setCharityTransactions(response);
      })
    );
  };

  return (
    <div className="approve-users">
      <div className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Grid container spacing={3} className={classes.grid}>
                  <Grid item xs={12}>
                    <AsyncAutoComplete setCharities={setCharities} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" gutterBottom>
                      Charity Name
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" gutterBottom>
                      {charities?.charityName || "N/A"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" gutterBottom>
                      Charity Number
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" gutterBottom>
                      {charities?.charityNumber || "N/A"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" gutterBottom>
                      Charity Address
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" gutterBottom>
                      {charities?.charityAddress || "N/A"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" gutterBottom>
                      Total Merchants Donated
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" gutterBottom>
                      {charities?.totalTransactions?.length || "N/A"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" gutterBottom>
                      Total No. of Transactions
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" gutterBottom>
                      {charities?.count || "N/A"}
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {charities?.count && (
                        <>
                          {checked ? (
                            <Link
                              color="primary"
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={() => showTransactions()}
                            >
                              Hide Transactions
                            </Link>
                          ) : (
                            <Link
                              color="primary"
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={() => showTransactions()}
                            >
                              Show Transactions
                            </Link>
                          )}
                        </>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Collapse in={checked}>
                  <CharityTransactions
                    charityTransactions={charityTransactions}
                  />
                </Collapse>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <CharityTable
                rows={charityArray || []}
                handleDelete={handleDelete}
                handleFormUpdate={handleAddCharity}
                handleApprove={handleApprove}
                // addCharity={addCharity}
              />
            </Grid>
          </Grid>
        </Container>
        <CustomSnackbar {...snackbar} />
      </div>
    </div>
  );
};
