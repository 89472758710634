import React, { useState, useEffect } from "react";
import { Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { styled } from "@material-ui/core/styles";
import PlacesAutocomplete from "../../utilities/PlacesAutocomplete";
import * as actions from "../../../../actions/charity";
import { useDispatch } from "react-redux";

const charityOptions = [
  {
    id: 1,
    name: "The Smile Foundation",
  },
  {
    id: 2,
    name: "Foundation Logistics Centre",
  },
  {
    id: 3,
    name: "Feed the Babies",
  },
  {
    id: 4,
    name: "Hope Charity Organization",
  },
  {
    id: 5,
    name: "Children’s Charities Association",
  },
  {
    id: 6,
    name: "Save the Community",
  },
  {
    id: 7,
    name: "Venture Philanthropy",
  },
  {
    id: 8,
    name: "Sisters of Charity",
  },
];
const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: 16,
    minWidth: 270,
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    marginTop: 20,
    padding: 50,
    marginBottom: 20,
    borderRadius: 20,
  },
}));

export default function FirstStep({
  handleChange,
  values: {
    selectedCharity,
    manualCharityEntry,
    charityName,
    charityWebsite,
    charityNumber,
    charityAddress,
    serverCharity,
    receivedFromServer,
  },
  formErrors,
  handleNext,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  // const [charities, setCharity] = useState([]);

  // useEffect(() => {
  //   dispatch(
  //     actions.getAllCharities(({ status, message }) => {
  //       if (status == "success") {
  //         setCharity(message);
  //       }
  //     })
  //   );
  //   return () => {};
  // }, []);

  const isValid = () => {
    if (!manualCharityEntry) {
      return !!selectedCharity._id || true;
    } else {
      return (
        charityName.length > 0 &&
        charityWebsite.length > 0 &&
        charityNumber >= 0 &&
        charityAddress.length > 0
      );
    }
  };

  console.log(selectedCharity, "selectedCharity");
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-controlled-open-select-label">
              {receivedFromServer ? "" : "Charity"}
            </InputLabel>
            {/* {receivedFromServer ? (
              <>
                <TextField
                  disabled={true}
                  value={selectedCharity.charityName}
                />
              </>
            ) : ( */}
            <Select
              labelId="demo-controlled-open-select-label"
              fullWidth
              // disabled={manualCharityEntry}
              name="selectedCharity"
              value={selectedCharity || ""}
              onChange={handleChange}
            >
              {serverCharity.map((charity, index) => (
                <MenuItem key={index} value={charity}>
                  {(charity && charity.charityName) || ""}
                </MenuItem>
              ))}
            </Select>
            {/* )} */}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={manualCharityEntry}
                onChange={handleChange}
                name="manualCharityEntry"
                color="primary"
              />
            }
            style={{ marginTop: "20px" }}
            label="Enter Manually"
          />
        </Grid>
        {manualCharityEntry && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                style={{ textAlign: "center", marginTop: 10 }}
                component="h1"
                variant="h6"
              >
                Fill the details
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                fullWidth
                label="Charity Name"
                name="charityName"
                placeholder="Charity Name"
                margin="normal"
                value={charityName || ""}
                onChange={handleChange}
                error={!!formErrors.charityName}
                helperText={formErrors.charityName}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                fullWidth
                label="Charity Website"
                name="charityWebsite"
                placeholder="Charity Website"
                margin="normal"
                value={charityWebsite || ""}
                onChange={handleChange}
                error={!!formErrors.charityWebsite}
                helperText={formErrors.charityWebsite}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                fullWidth
                label="Charity Number"
                name="charityNumber"
                placeholder="Charity Number"
                margin="normal"
                value={charityNumber || ""}
                onChange={handleChange}
                error={!!formErrors.charityNumber}
                helperText={formErrors.charityNumber}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                fullWidth
                label="Charity Address"
                name="charityAddress"
                placeholder="charity Address"
                margin="normal"
                value={charityAddress || ""}
                onChange={handleChange}
                error={!!formErrors.charityAddress}
                helperText={formErrors.charityAddress}
                required
              />
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              marginTop: 10,
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              disabled={!isValid()}
              color="primary"
              onClick={isValid() ? handleNext : null}
            >
              Next
            </Button>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
