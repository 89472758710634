import axios from "axios";
import {
  AUTH_USER,
  AUTH_ROLE,
  AUTH_ERROR,
  CLEAR_ERROR,
  MESSAGE,
  IS_USER_HAS_DETAILS,
} from "../types";
import { SERVER_URL } from "../../constants";

const attachHeaders = () => {
  const token = localStorage.getItem("token");
  const header = { authorization: token };
  return header;
};

export const submitManualCharityRequest =
  (charity, callBack) => async (dispatch) => {
    const header = attachHeaders();
    charity.userId = localStorage.getItem("userId");
    try {
      const { data } = await axios.post(`${SERVER_URL}charity`, charity, {
        headers: header,
      });
      const { status, response } = data;
      console.log(data, "data");
      callBack({ status, response });
    } catch (e) {
      callBack({ status: "error", message: e.response.data.msg });
    }
  };

export const submitCharity = (charity, callBack) => async (dispatch) => {
  const header = attachHeaders();
  charity.userId = localStorage.getItem("userId");
  try {
    const { data } = await axios.post(`${SERVER_URL}userDetail`, charity, {
      headers: header,
    });
    const { response: res, status } = data;
    if (charity.isManual) {
      callBack({
        status,
        message:
          "Our team is reviewing your charity. Please look for an email update in the next 2-3 business days.",
        res,
      });
    } else {
      dispatch({
        type: IS_USER_HAS_DETAILS,
        payload: "true",
      });
      localStorage.setItem("isUserhasDetails", "true");
      callBack({ status, message: "Charity Submitted Successfully !" });
    }
  } catch (e) {
    callBack({ message: "Oops ! Please try again later", status: "error" });
  }
};

export const getAllCharities = (callBack) => async (dispatch) => {
  try {
    const header = attachHeaders();
    const { data } = await axios.get(`${SERVER_URL}charity`, {
      headers: header,
    });
    const { status, response } = data;

    callBack({ status, message: Array.isArray(response) ? response : [] });
  } catch (e) {}
};

export const getCharityByID = (callBack) => async (dispatch) => {
  try {
    const header = attachHeaders();
    const userId = localStorage.getItem("userId");
    const { data } = await axios.get(`${SERVER_URL}userDetail?id=${userId}`, {
      headers: header,
    });
    const { status, response } = data;
    callBack({ status, message: Array.isArray(response) ? response : [] });
  } catch (e) {}
};

export const updateCharity = (body, callBack) => async (dispatch) => {
  try {
    const header = attachHeaders();
    body.userId = localStorage.getItem("userId");
    const { data } = await axios.put(`${SERVER_URL}charity`, body, {
      headers: header,
    });
    const { status, response } = data;
    callBack({ status, message: response });
  } catch (e) {
    callBack({ message: "Oops ! Please try again later", status: "error" });
  }
};

export const deleteCharity = (body, callBack) => async (dispatch) => {
  try {
    const header = attachHeaders();
    const { data } = await axios.delete(`${SERVER_URL}charity`, {
      data: body,
      headers: header,
    });
    const { response, status } = data;
    dispatch({
      type: MESSAGE,
      payload: {
        status,
        message: response,
      },
    });

    callBack({ message: response, status });
  } catch (e) {
    callBack({ message: "Oops ! Please try again later", status: "error" });
  }
};

export const approveCharity = (body, callBack) => async (dispatch) => {
  try {
    const header = attachHeaders();
    const { data } = await axios.put(`${SERVER_URL}charity/approve`, body, {
      headers: header,
    });
    const { response, status } = data;
    dispatch({
      type: MESSAGE,
      payload: {
        status,
        message: response,
      },
    });

    callBack({ message: response, status });
  } catch (e) {
    callBack({ message: "Oops ! Please try again later", status: "error" });
  }
};

export const searchCharity = (text, callBack) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${SERVER_URL}charity/query?charityName=${encodeURIComponent(text)}`
    );
    const { response, status } = data;
    callBack(response);
  } catch (e) {
    dispatch({
      type: MESSAGE,
      payload: {
        status: "error",
        message: "Oops ! Please try again later",
      },
    });
  }
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERROR,
    payload: "",
  };
};
