import React from "react";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

// Destructuring props
const SecondStep = ({
  handleNext,
  handleBack,
  handleChange,
  values,
  formErrors,
  updateInfo,
}) => {
  const { companyAddress, companyTown, companyCountry, companyPostcode } =
    values;

  // Check if all values are not empty or if there are some error
  const isValid =
    companyAddress.length > 0 &&
    !formErrors.companyAddress &&
    companyTown.length > 0 &&
    !formErrors.companyTown &&
    companyCountry.length > 0 &&
    !formErrors.companyCountry &&
    companyCountry.length > 0 &&
    !formErrors.companyCountry;
  const handleSubmit = () => {
    // Do whatever with the values
    updateInfo(values);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            fullWidth
            label="Company Address"
            name="companyAddress"
            placeholder="Enter Company Address"
            value={companyAddress || ""}
            margin="normal"
            onChange={handleChange}
            error={!!formErrors.companyAddress}
            helperText={formErrors.companyAddress}
            required
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            fullWidth
            label="Company Town"
            name="companyTown"
            placeholder="Enter Company Town"
            value={companyTown || ""}
            margin="normal"
            onChange={handleChange}
            error={!!formErrors.companyTown}
            helperText={formErrors.companyTown}
            required
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            fullWidth
            label="Company Country"
            name="companyCountry"
            placeholder="Enter Company Country"
            value={companyCountry || ""}
            margin="normal"
            onChange={handleChange}
            error={!!formErrors.companyCountry}
            helperText={formErrors.companyCountry}
            required
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            fullWidth
            label="Company PostCode"
            name="companyPostcode"
            placeholder="Enter Company PostCode"
            value={companyPostcode || ""}
            margin="normal"
            onChange={handleChange}
            error={!!formErrors.companyPostcode}
            helperText={formErrors.companyPostcode}
          />
        </Grid>
      </Grid>
      <div
        style={{ display: "flex", marginTop: 50, justifyContent: "flex-end" }}
      >
        <Button
          variant="contained"
          color="default"
          onClick={handleBack}
          style={{ marginRight: 10 }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          disabled={!isValid}
          color="primary"
          onClick={isValid ? handleSubmit : null}
        >
          Update
        </Button>
      </div>
    </>
  );
};

export default SecondStep;
