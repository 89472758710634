const currencyList = [
  {
    name: "US Dollars",
    code: "USD",
    symbol: "$",
  },
  {
    name: "Pound",
    code: "PD",
    symbol: "£",
  },
  {
    name: "Euro",
    code: "EURO",
    symbol: "€",
  },
  {
    name: "Canadian Dollars",
    code: "CAD",
    symbol: "CA$",
  },
  {
    name: "Australian Dollar",
    code: "AUD",
    symbol: "A$",
  },
];

export const returnCurrencyObj = (name) => {
  const arr = currencyList.filter((curreny) => curreny.name === name);
  const obj = arr.length > 0 ? arr[0] : {};
  return obj;
};

export default currencyList;

/*
Charity Amount Rename to Donation Amount
Remove Billing Address
Charity Address - add google autocomplete - UK Region
Add/Edit Charity API
The role should be persisted on refresh
 Admin Setting - 
All Charity lists - 
with actions - edit, update, add.
Approve/UnApprove Change Colors
Merchant - add Profile in dropdown. - add Stripe Payment 

Start Charity STEP 3.
*/
