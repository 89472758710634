import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: 16,
    minWidth: 270,
  },
}));

const CartDetails = ({
  handleNext,
  handleBack,
  handleChange,
  values: { cartPageId, buyNowId },
  formErrors,
}) => {
  const classes = useStyles();
  // Check if all values are not empty or if there are some error
  const isValid =
    cartPageId.length > 0 &&
    !formErrors.cartPageId &&
    buyNowId.length > 0 &&
    !formErrors.buyNowId;

  return (
    <Fragment>
      <Grid container spacing={2} noValidate>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            variant="outlined"
            label="Cart Page Id"
            name="cartPageId"
            placeholder="Enter Cart Page Id"
            margin="normal"
            value={cartPageId || ""}
            onChange={handleChange}
            error={!!formErrors.cartPageId}
            helperText={formErrors.cartPageId}
            autoFocus
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            fullWidth
            label="Buy Now Id"
            name="buyNowId"
            placeholder="Buy Now Id"
            margin="normal"
            value={buyNowId || ""}
            onChange={handleChange}
            error={!!formErrors.buyNowId}
            helperText={formErrors.buyNowId}
            required
          />
        </Grid>
      </Grid>
      <div
        style={{ display: "flex", marginTop: 50, justifyContent: "flex-end" }}
      >
        <Button
          variant="contained"
          color="default"
          onClick={handleBack}
          style={{ marginRight: 10 }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          disabled={!isValid}
          color="primary"
          onClick={isValid ? handleNext : null}
        >
          Next
        </Button>
      </div>
    </Fragment>
  );
};

export default CartDetails;
