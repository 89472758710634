import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { FE_SERVER_URL } from "../../../constants";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export const UserProfileModal = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (window.location.href == FE_SERVER_URL + "dashboard/userProfile") {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [window.location.href]);

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Attention!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p>
              Please fill your Billing Details{" "}
              <Link to={"/dashboard/userProfile"} variant="body2">
                {"click here"}
              </Link>
            </p>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};
