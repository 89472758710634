import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const HIDE_DURATION = 3000;

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const CustomSnackbar = ({ isOpen, isError, message, handleClose }) => {
  const severity =
    isError === true || isError === "error" ? "error" : "success";
  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={HIDE_DURATION}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
