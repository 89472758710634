import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Header,
  Footer,
  Home,
  SignUp,
  SignIn,
  SignOut,
  Dashboard,
  ForgotPassword,
  ContactUs,
  ResetPassword,
} from "./Components";

export const AppRouter = () => {
  const auth = useSelector((state) => state.auth.authenticated);
  const PrivateRoute = ({ component: Component, authed, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) =>
          authed ? (
            <>
              <Component {...props} />
              <Route path={`dashboard/transactions`}>
                <SignIn />
              </Route>
            </>
          ) : (
            <Redirect
              to={{ pathname: "/signIn", state: { from: props.location } }}
            />
          )
        }
      />
    );
  };
  return (
    <Router>
      <Switch>
        <Route path="/signUp">
          <Header />
          <SignUp />
        </Route>
        <Route path="/signIn">
          <Header />
          <SignIn />
        </Route>
        <Route path="/signIn">
          <Header />
          <SignOut />
        </Route>
        <Route path="/contactUs">
          <Header />
          <ContactUs />
        </Route>
        <Route path="/forgotPassword">
          <Header />
          <ForgotPassword />
        </Route>
        <Route path="/resetPassword/:id/:token">
          <Header />
          <ResetPassword />
        </Route>
        <PrivateRoute authed={auth} path="/dashboard" component={Dashboard} />
        <Route path="/">
          <Header />
          <Home />
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
};

export default AppRouter;
