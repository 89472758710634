import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";

import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  gridTitle: {
    fontSize: "1.2em",
    lineHeight: 1.5,
    padding: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6),
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: 0,
    },
  },
  gridTitleStyled: {
    maxWidth: "3.25rem",
    marginLeft: 0,
    borderTopWidth: "0.3125rem",
    borderTopColor: "#31409E",
    margin: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(6),
      marginTop: 0,
      marginRight: 0,
      marginBottom: theme.spacing(3),
    },
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  mainFeaturedPost: {
    position: "relative",
    marginBottom: theme.spacing(4),
  },
  mainFeaturedPostContent: {
    position: "relative",
    padding: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6),
      paddingRight: 0,
    },
  },
}));

const mainFeaturedPost = {
  title: "Add donations to your online retail experience.",
  description:
    "Inspire your customers to easily make an impact through your business. In less than 5 minutes, your business can support a cause or multiple causes of your choice. Immediately visualize and share impact as purchases are made. Watch your business grow through giving.",
};

export const EcommerceSection = () => {
  const classes = useStyles();
  const history = useHistory();

  const auth = useSelector((state) => state.auth);
  const startDonating = () => {
    if (auth.authenticated) {
      history.push("/dashboard/settings");
    } else {
      history.push("/signIn");
    }
  };

  return (
    <div className="ecommerceSection">
      <Container component="main" className={classes.main} maxWidth="lg">
        <div className={classes.mainFeaturedPost}>
          <Typography
            variant="button"
            display="block"
            gutterBottom
            className={classes.gridTitle}
          >
            E-COMMERCE PLUGINS
          </Typography>
          <hr className={classes.gridTitleStyled} />
          <Grid container>
            <Grid item md={6}>
              <div className={classes.mainFeaturedPostContent}>
                <Typography
                  component="h1"
                  variant="h3"
                  color="inherit"
                  gutterBottom
                >
                  {mainFeaturedPost.title}
                </Typography>
                <Typography variant="body" color="inherit" paragraph>
                  {mainFeaturedPost.description}
                </Typography>

                <Button
                  color="primary"
                  variant="contained"
                  className={classes.link}
                  size="large"
                  component={RouterLink}
                  onClick={() => {
                    startDonating();
                  }}
                >
                  Start Donating
                </Button>
              </div>
            </Grid>
            <Grid item md={6} className={classes.image} />
          </Grid>
        </div>
      </Container>
    </div>
  );
};
