import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import FirstStep from "./Steps/FirstStep";
import SecondStep from "./Steps/SecondStep";
import CustomSnackbar from "../../Shared/components/CustomSnackbar";
import formValidation from "../../Shared/utilities/formValidation";
import * as actions from "../../../actions/charity";
import Stepper from "@material-ui/core/Stepper";
import Paper from "@material-ui/core/Paper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import AttachMoneyOutlined from "@material-ui/icons/AttachMoneyOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CartDetails from "../IntegrationSteps/steps/CartDetails";
import CustomizeDonationButton from "../IntegrationSteps/steps/CustomizeDonationButton";
import GenerateScript from "../IntegrationSteps/steps/GenerateScript";
import InProgress from "../IntegrationSteps/steps/InProgress";
import { Backdrop } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { returnCurrencyObj } from "../../Shared/utilities/currencyList";

const useStyles = makeStyles((theme) => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 900,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
      padding: theme.spacing(3),
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const labels = [
  "Pick Charity",
  "Donation Amount",
  // "Your Ecomm Cart Page Details",
  "Donation Preview",
  "Generate Script",
];

const initialValues = {
  selectedCharity: {
    _id: "",
    charityName: "",
    charityAddress: "",
    charityName: "",
    charityNumber: "",
    charityWebsite: "",
  },
  manualCharityEntry: false,
  receivedFromServer: false,
  serverCharity: [],
  charityName: "",
  charityWebsite: "",
  charityNumber: "",
  charityAddress: "",
  defaultCurrency: {
    name: "",
    symbol: "",
    code: "",
  },
  donationAmount: "",
  cartPageId: "",
  buyNowId: "",
  buttonText: "Donate",
  buttonColor: "red",
  buttonShape: "pill",
  firstName: "",
  lastName: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  country: "US",
  termscheckbox: false,
  openBackDrop: false,
};

const fieldsValidation = {
  selectedCharity: {
    error: "",
    validate: "",
  },
  manualCharityEntry: {
    error: "",
    validate: "",
  },
  charityName: {
    error: "",
    validate: "required",
  },
  charityWebsite: {
    error: "",
    validate: "required",
  },
  charityNumber: {
    error: "",
    validate: "number",
  },
  charityAddress: {
    error: "",
    validate: "required",
  },
  defaultCurrency: {
    error: "",
    validate: "",
  },
  donationAmount: {
    error: "",
    validate: "number",
  },
  cartPageId: {
    error: "",
    validate: "required",
  },
  buyNowId: {
    error: "",
    validate: "required",
  },
  buttonText: {
    error: "",
    validate: "required",
  },
  buttonColor: {
    error: "",
    validate: "required",
  },
  buttonShape: {
    error: "",
    validate: "required",
  },
  firstName: {
    error: "",
    validate: "required",
  },
  lastName: {
    error: "",
    validate: "required",
  },
  addressLine1: {
    error: "",
    validate: "required",
  },
  addressLine2: {},
  city: {
    error: "",
    validate: "required",
  },
  state: {
    error: "",
    validate: "required",
  },
  country: {
    error: "",
    validate: "required",
  },
  termscheckbox: {},
};

export const Settings = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { message: messageObj } = useSelector((state) => state.message);
  const isUserhasDetails = useSelector((state) => state.auth.isUserhasDetails);
  const location = useLocation();

  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isCampaignCreated, setIsCampaignCreated] = useState(false);
  const [isManualCharity, setIsManualCharity] = useState(false);

  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    isError: "",
    message: "",
    handleClose: () => closeSnackbar(),
  });

  const fillInitializedData = (charity) => {
    const { picked_charity, donationDetails } = charity;
    const charityObj = initialValues.selectedCharity;
    charityObj.charityName = picked_charity.charityName;
    charityObj.charityId = picked_charity.charityId;
    charityObj._id = picked_charity.charityId;
    const defaultCurrency = returnCurrencyObj(donationDetails.currency);

    setFormValues((prevValue) => ({
      ...prevValue,
      receivedFromServer: true,
      selectedCharity: charityObj,
      defaultCurrency,
      donationAmount: donationDetails.donation_amount || 0,
    }));
  };

  const toggleBackDrop = () => {
    setFormValues((prevValue) => ({
      ...prevValue,
      openBackDrop: !prevValue.openBackDrop,
    }));
  };

  useEffect(() => {
    const { status, message } = messageObj;
    if (status && message) {
      setSnackbar((preVal) => ({
        ...preVal,
        isOpen: true,
        isError: status,
        message: message,
      }));
      dispatch(actions.clearErrors());
    }
  }, [messageObj]);

  useEffect(() => {
    if (isUserhasDetails === "false") {
      callCharities();
    }
  }, []);

  useEffect(() => {
    if (isUserhasDetails === "inActive") {
      setIsManualCharity(true);
    }
  }, []);

  useEffect(() => {
    const { state = {} } = location;
    if (state?.isEdit || isUserhasDetails === "false") {
      setIsCampaignCreated(true);
      getCharityByID();
      callCharities();
    }
  }, []);

  const callCharities = () => {
    dispatch(
      actions.getAllCharities(({ status, message }) => {
        if (status == "success") {
          setFormValues((prevValue) => ({
            ...prevValue,
            serverCharity: message,
          }));

          getCharityByID();
        }
      })
    );
  };

  const getCharityByID = () => {
    dispatch(
      actions.getCharityByID(({ status, message }) => {
        if (status == "success") {
          if (message.length >= 1) {
            setIsCampaignCreated(true);
            fillInitializedData(message[0]);
          }
        }
      })
    );
  };

  // Proceed to next step
  const handleNext = () => {
    if (activeStep == 2) {
      // toggleBackDrop();
      sendCharityRequest();
    } else {
      setActiveStep((prev) => prev + 1);
    }
  };
  // Go back to prev step
  const handleBack = () => setActiveStep((prev) => prev - 1);

  const sendCharityRequest = async () => {
    console.log(formValues, "formValues");
    let body = {};
    if (formValues.manualCharityEntry) {
      body.charityName = formValues.charityName;
      body.charityWebsite = formValues.charityWebsite;
      body.charityNumber = formValues.charityNumber;
      body.charityAddress = formValues.charityAddress;

      body.isManual = true;
      dispatch(
        actions.submitManualCharityRequest(body, ({ status, response }) => {
          console.log(status, response, "status, response ");
          if (status === "success") {
            let newBody = {};
            newBody.isManual = true;
            newBody.donationDetails = {
              currency: formValues.defaultCurrency.name,
              donation_amount: formValues.donationAmount,
            };
            newBody.picked_charity = {
              charityId: response._id,
              charityName: response.charityName,
            };
            dispatch(
              actions.submitCharity(newBody, ({ message, status, res }) => {
                console.log(res, "res");
                setIsManualCharity(true);
                setSnackbar((preVal) => ({
                  ...preVal,
                  isOpen: true,
                  isError: status,
                  message: message,
                }));
              })
            );
          }
        })
      );
    } else {
      body.isManual = false;
      body.id = formValues.selectedCharity._id;
      body.picked_charity = formValues.selectedCharity.charityName;
      body.donationDetails = {
        currency: formValues.defaultCurrency.name,
        donation_amount: formValues.donationAmount,
      };
      dispatch(
        actions.submitCharity(body, ({ status, message }) => {
          if (status === "success") {
            setActiveStep((prev) => prev + 1);
          }
        })
      );
    }
  };

  // Handle form change
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Set values
    if (name === "manualCharityEntry") {
      let currentValue = formValues.manualCharityEntry;
      setFormValues((prev) => ({
        ...prev,
        manualCharityEntry: !currentValue,
      }));
    } else {
      setFormValues((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    // set errors
    const error = formValidation(name, value, fieldsValidation) || "";

    setFormErrors((prev) => ({
      ...prev,
      [name]: error,
    }));
  };

  const handleSteps = (step) => {
    switch (step) {
      case 0:
        return (
          <FirstStep
            handleNext={handleNext}
            handleChange={handleChange}
            // handleBack={handleBack}
            values={formValues}
            formErrors={formErrors}
          />
        );
      case 1:
        return (
          <SecondStep
            handleNext={handleNext}
            handleBack={handleBack}
            handleChange={handleChange}
            values={formValues}
            formErrors={formErrors}
          />
        );
      case 2:
        return (
          <CustomizeDonationButton
            handleNext={handleNext}
            handleBack={handleBack}
            handleChange={handleChange}
            values={formValues}
            formErrors={formErrors}
            toggleBackDrop={toggleBackDrop}
          />
        );
      case 3:
        return <GenerateScript />;
      default:
        break;
    }
  };

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar((preVal) => ({
      ...preVal,
      isOpen: false,
    }));
  };

  return (
    <>
      {" "}
      <Backdrop
        sx={{
          color: "#000",
          // display: "flex",
        }}
        open={formValues.openBackDrop}
        onClick={() => toggleBackDrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="signup">
        <Container component="main">
          <CssBaseline />
          <div className={classes.layout}>
            {!isManualCharity ? (
              <>
                {isCampaignCreated ? (
                  <Paper className={classes.paper}>
                    <Avatar className={classes.avatar}>
                      <AttachMoneyOutlined />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                      Create Campaign
                    </Typography>
                    <>
                      {activeStep === labels.length ? (
                        // Last Component
                        //   <Success values={formValues} />
                        <></>
                      ) : (
                        <>
                          <Stepper
                            activeStep={activeStep}
                            style={{
                              margin: "30px 0 15px",
                            }}
                            alternativeLabel
                          >
                            {labels.map((label) => (
                              <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                              </Step>
                            ))}
                          </Stepper>
                          {handleSteps(activeStep)}
                        </>
                      )}
                    </>
                  </Paper>
                ) : (
                  <Paper className={classes.paper}>
                    <Avatar className={classes.avatar}>
                      <AttachMoneyOutlined />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                      Thank you for subscribing us!
                    </Typography>
                    <GenerateScript />
                  </Paper>
                )}
              </>
            ) : null}
            {isManualCharity && <InProgress />}
          </div>
        </Container>
        <CustomSnackbar {...snackbar} />
      </div>
    </>
  );
};
