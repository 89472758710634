import {
  AUTH_ROLE,
  AUTH_USER,
  AUTH_ERROR,
  IS_USER_HAS_BILLING,
  CLEAR_ERROR,
  IS_USER_HAS_DETAILS,
} from "../actions/types";

const INITIAL_STATE = {
  authenticated: "",
  authenticatedRole: "",
  isUserHasBilling: "false",
  isUserhasDetails: "false",
  errorMessage: "",
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH_USER:
      return { ...state, authenticated: action.payload };
    case AUTH_ROLE:
      return { ...state, authenticatedRole: action.payload };
    case IS_USER_HAS_BILLING:
      return { ...state, isUserHasBilling: action.payload };
    case IS_USER_HAS_DETAILS:
      return { ...state, isUserhasDetails: action.payload };
    case AUTH_ERROR:
      return { ...state, errorMessage: action.payload };

    case CLEAR_ERROR:
      return { ...state, errorMessage: action.payload };
    default:
      return state;
  }
};

export default reducer;
