import { GET_USER_BILLING } from "../actions/types";

const INITIAL_STATE = {
  userBilling: {
    firstName: "",
    lastName: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    // zip,
    country: "",
    cardInfo: {
      exp_month: 0,
      exp_year: 0,
      last4: "",
    },
  },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_USER_BILLING:
      return { ...state, userBilling: action.payload };
    default:
      return state;
  }
};

export default reducer;
