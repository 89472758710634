import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";

import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  image: {
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  margin: {
    margin: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(6),
      marginTop: 0,
      marginBottom: theme.spacing(3),
      marginRight: 0,
    },
  },
  gridTitle: {
    fontSize: "1.2em",
    lineHeight: 1.5,
  },
  gridTitleStyled: {
    maxWidth: "3.25rem",
    marginLeft: 0,
    borderTopWidth: "0.3125rem",
    borderTopColor: "#31409E",
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
      marginBottom: 0,
      marginRight: 0,
    },
  },
  mainFeaturedPost: {
    position: "relative",
    marginBottom: theme.spacing(4),
  },
  mainFeaturedPostContent: {
    position: "relative",
    padding: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6),
      paddingRight: 0,
    },
  },
}));

const mainFeaturedPost = {
  title: "Activate charitable giving on Shopify",
  description:
    "App on Shopify makes it quick and easy for your business to start giving back. Simply choose your cause or fund, pick a style, and select your donation type. It’s that simple!",
};

export const ShopifySection = () => {
  const classes = useStyles();
  return (
    <div className="shopifySection">
      <Container component="main" className={classes.main} maxWidth="lg">
        <div className={classes.mainFeaturedPost}>
          <Grid container className={classes.margin}>
            <Grid item md={6}></Grid>
            <Grid item md={6}>
              <Typography
                variant="button"
                display="block"
                gutterBottom
                className={classes.gridTitle}
              >
                SHOPIFY PLUGINS
              </Typography>
              <hr className={classes.gridTitleStyled} />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item md={6} className={classes.image} />
            <Grid item md={6}>
              <div className={classes.mainFeaturedPostContent}>
                <Typography
                  component="h1"
                  variant="h3"
                  color="inherit"
                  gutterBottom
                >
                  {mainFeaturedPost.title}
                </Typography>
                <Typography variant="body" color="inherit" paragraph>
                  {mainFeaturedPost.description}
                </Typography>
                <Button
                  variant="outlined"
                  className={classes.link}
                  size="large"
                  color="primary"
                >
                  Learn More
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default ShopifySection;
