import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import CustomSnackbar from "../../../Shared/components/CustomSnackbar";

import "./GenerateScript.scss";

const script = "http://localhost:3000/custom-checkbox/script.js";

const useStyles = makeStyles((theme) => ({
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
}));

const GenerateScript = () => {
  const classes = useStyles();
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    isError: "",
    message: "",
    handleClose: () => closeSnackbar(),
  });
  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar((preVal) => ({
      ...preVal,
      isOpen: false,
    }));
  };
  const copyToClipBoard = (text) => {
    navigator.clipboard.writeText(text);

    setSnackbar((preVal) => ({
      ...preVal,
      isOpen: true,
      isError: false,
      message: "Copied to clipboard !",
    }));
  };

  return (
    <Container maxWidth="sm" component="main" className={classes.heroContent}>
      <Typography
        component="h1"
        variant="h4"
        align="center"
        color="textPrimary"
        gutterBottom
      >
        Please Find your Script & Element.
      </Typography>
      <Typography
        variant="h5"
        align="center"
        color="textSecondary"
        component="p"
      >
        T&C
      </Typography>
      <Grid container spacing={2} noValidate>
        <Grid item xs={12} sm={12}>
          <h3>Add the tag in your Cart Page</h3>
          <pre>
            <code>
              <span className="token punctuation">&lt;</span>
              <span className="token code">donation-plugin</span>
              <span className="token string">
                &nbsp;{`data-userId='${localStorage.getItem("userId")}'`}
              </span>
              <span className="token punctuation">&gt;</span>
              <span className="token punctuation">&lt;</span>
              <span className="token code">/donation-plugin</span>
              <span className="token punctuation">&gt;</span>
            </code>
            <span
              className="clipboard"
              onClick={() =>
                copyToClipBoard(
                  `<donation-plugin data-userId="${localStorage.getItem(
                    "userId"
                  )}"></donation-plugin>`
                )
              }
            >
              Copy
            </span>
          </pre>
          <h3>Add the script in your index.html file</h3>
          <pre>
            <code>
              <span className="token code">
                <span className="token punctuation">&lt;</span>
                <span className="token code">script </span>
                <span className="token code">src</span>
                <span className="token code">=</span>
                <span className="token string">"</span>
                <span className="token string">{script}</span>
                <span className="token string">"</span>
                <span className="token code">&gt;</span>
                <span className="token punctuation">&lt;</span>
                <span className="token code">/script</span>
                <span className="token punctuation">&gt;</span>
              </span>
            </code>
            <span
              className="clipboard-2"
              onClick={() => copyToClipBoard(`<script src=${script}></script>`)}
            >
              Copy
            </span>
          </pre>
        </Grid>
      </Grid>
      <CustomSnackbar {...snackbar} />
    </Container>
  );
};

export default GenerateScript;
