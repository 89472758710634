import { MESSAGE, CLEAR_MESSAGE } from "../actions/types";

const INITIAL_STATE = {
  message: {
    status: "",
    message: "",
  },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MESSAGE:
      return { ...state, message: action.payload };
    case CLEAR_MESSAGE:
      return { ...state, message: action.payload };
    default:
      return state;
  }
};

export default reducer;
