import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { forgotPassword } from "../../../actions/auth";
import { clearMessages } from "../../../actions";
import formValidation from "../../Shared/utilities/formValidation";
import CustomSnackbar from "../../Shared/components/CustomSnackbar";

import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const initialValues = {
  email: "",
};

const fieldsValidation = {
  email: {
    error: "",
    validate: "email",
  },
};

export const ForgotPassword = () => {
  const classes = useStyles();
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    isError: "",
    message: "",
    handleClose: () => closeSnackbar(),
  });
  const dispatch = useDispatch();
  const { message: messageObj } = useSelector((state) => state.message);

  useEffect(() => {
    console.log(messageObj, "messageObj");
    const { status, message } = messageObj;
    if (status && message) {
      setSnackbar((preVal) => ({
        ...preVal,
        isOpen: true,
        isError: status,
        message: message,
      }));

      dispatch(clearMessages());
    }
  }, [messageObj]);

  // Handle form change
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Set values
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));

    // set errors
    const error = formValidation(name, value, fieldsValidation) || "";

    setFormErrors((prev) => ({
      ...prev,
      [name]: error,
    }));
  };

  const isValid = formValues.email.length > 0 && !formErrors.email;

  const submitForm = (e) => {
    e.preventDefault();
    dispatch(
      forgotPassword(formValues, () => {
        console.log("Submitted!");
        setFormValues(initialValues);
      })
    );
  };

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar((preVal) => ({
      ...preVal,
      isOpen: false,
    }));
  };

  return (
    <div className="forgotPassword">
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Password Reset
          </Typography>
          <Grid container spacing={2} noValidate>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="Email"
                name="email"
                placeholder="Enter Email"
                margin="normal"
                value={formValues.email || ""}
                onChange={handleChange}
                error={!!formErrors.email}
                helperText={formErrors.email}
                autoFocus
                required
              />
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              marginTop: 50,
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              disabled={!isValid}
              color="primary"
              onClick={isValid ? submitForm : null}
            >
              Reset Password
            </Button>
          </div>
        </div>
      </Container>
      <CustomSnackbar {...snackbar} />
    </div>
  );
};
