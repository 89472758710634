import React from "react";
import PaymentIcon from "@material-ui/icons/Payment";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: "flex",
    height: "45px",
    borderRadius: "10rem",
    padding: "10px",
  },

  cardNumber: {
    color: "#fff",
    marginLeft: "30px",
    fontSize: "16px",
  },
  expiry: {
    color: "#fff",
    float: "right",
    marginLeft: "800px",
    fontSize: "16px",
  },
}));

export default function DummyCard({ cardNumber, expMonth, expYear, toggle }) {
  const classes = useStyles();

  const formattedCard = `XXXX XXXX ${cardNumber}`;
  const formattedExpiry = `${expMonth}/${expYear}`;

  return (
    <>
      <Tooltip title="Click to edit card details">
        <div tool className={classes.cardContainer} onClick={toggle}>
          <PaymentIcon />
          <span className={classes.cardNumber}>{formattedCard}</span>
          <span className={classes.expiry}>{formattedExpiry}</span>
        </div>
      </Tooltip>
    </>
  );
}
