import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import formValidation from "../../Shared/utilities/formValidation";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/Edit";
import {
  addUserBilling,
  getUserBilling,
  updateUserBilling,
} from "../../../actions/userProfile";
import { clearMessages } from "../../../actions";
import CustomSnackbar from "../../Shared/components/CustomSnackbar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DummyCard from "./DummyCard";
import * as actions from "../../../actions/charity";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "#fff",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#fce883" },
      "::placeholder": { color: "#87bbfd" },
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "#ffc7ee",
    },
  },
  // value: {
  //   cardNumber: "1234567788",
  // },
};

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
      padding: theme.spacing(3),
    },
  },
  payment: {
    border: "1px solid #ccc",
    margin: "20px 0",
    padding: "20px",
  },
  userDetails: {
    width: "100%",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    padding: "16px",
  },
  shipping: {
    width: "100%",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    padding: "16px",
  },
  formControl: {
    width: "100%",
  },
}));

const initialValues = {
  firstName: "",
  lastName: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  // zip,
  country: "",
  termscheckbox: false,
};

const fieldsValidation = {
  firstName: {
    error: "",
    validate: "required",
  },
  lastName: {
    error: "",
    validate: "required",
  },
  addressLine1: {
    error: "",
    validate: "required",
  },
  addressLine2: {
    error: "",
    validate: "required",
  },
  city: {
    error: "",
    validate: "required",
  },
  state: {
    error: "",
    validate: "required",
  },
  country: {
    error: "",
    validate: "required",
  },
  termscheckbox: {},
};

export const UserProfile = () => {
  const classes = useStyles();
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const { message: messageObj } = useSelector((state) => state.message);
  const userBilling = useSelector((state) => state.userProfile.userBilling);
  const [showDummyCard, setShowDummyCard] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const isUserhasDetails = useSelector((state) => state.auth.isUserhasDetails);
  const history = useHistory();

  const stripe = useStripe();
  const elements = useElements();

  const dispatch = useDispatch();
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    isError: "",
    message: "",
    handleClose: () => closeSnackbar(),
  });

  useEffect(() => {
    const { status, message } = messageObj;
    if (status && message) {
      setSnackbar((preVal) => ({
        ...preVal,
        isOpen: true,
        isError: status,
        message: message,
      }));

      dispatch(clearMessages());
    }
  }, [messageObj]);

  useEffect(() => {
    dispatch(getUserBilling());
  }, []);

  useEffect(() => {
    dispatch(
      actions.getCharityByID(({ status, message }) => {
        if (status == "success") {
          if (message.length >= 1) {
            setUserDetails(message[0]);
          }
        }
      })
    );
  }, []);

  useEffect(() => {
    setFormValues((prev) => ({
      ...prev,
      ...userBilling,
    }));

    if (userBilling.cardInfo && userBilling.cardInfo.last4) {
      setShowDummyCard(true);
    }
  }, [userBilling]);

  // Handle form change
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Set values
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));

    // set errors
    const error = formValidation(name, value, fieldsValidation) || "";

    setFormErrors((prev) => ({
      ...prev,
      [name]: error,
    }));
  };

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar((preVal) => ({
      ...preVal,
      isOpen: false,
    }));
  };

  const isValid =
    formValues.firstName.length > 0 &&
    !formErrors.firstName &&
    formValues.lastName.length > 0 &&
    !formErrors.lastName &&
    formValues.addressLine1.length > 0 &&
    !formErrors.addressLine1 &&
    formValues.addressLine2.length > 0 &&
    !formErrors.addressLine2 &&
    formValues.city.length > 0 &&
    !formErrors.city &&
    formValues.state.length > 0 &&
    !formErrors.state &&
    formValues.country.length > 0 &&
    !formErrors.country &&
    formValues.termscheckbox;

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (!error) {
      try {
        const { id } = paymentMethod;

        dispatch(
          addUserBilling({ ...formValues, id }, () => {
            history.push("/dashboard/transactions");
          })
        );
      } catch (error) {
        setSnackbar((preVal) => ({
          ...preVal,
          isOpen: true,
          isError: true,
          message: error,
        }));
      }
    } else {
      setSnackbar((preVal) => ({
        ...preVal,
        isOpen: true,
        isError: true,
        message: error.message,
      }));
    }
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    dispatch(
      updateUserBilling(formValues, ({ message, status }) => {
        setSnackbar((preVal) => ({
          ...preVal,
          isOpen: true,
          isError: status,
          message: message,
        }));
      })
    );
  };

  const toggleDummyCard = () => setShowDummyCard(false);
  const editCampaignDetails = () => {
    history.push("/dashboard/settings", { isEdit: true });
  };

  return (
    <div className="userProfile">
      <Container component="main">
        <CssBaseline />
        <Paper className={classes.paper}>
          {isUserhasDetails === "true" ? (
            <>
              <Typography component="h1" variant="h5">
                User Profile
              </Typography>
              <div className={classes.userDetails}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography variant="h6" gutterBottom>
                    Campaign Details
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => editCampaignDetails()}
                    style={{ marginRight: "5px", marginLeft: "5px" }}
                  >
                    <EditIcon />
                  </Button>
                </div>
                <Grid container spacing={3} className={classes.grid}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" gutterBottom>
                      Charity
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" gutterBottom>
                      {userDetails?.picked_charity?.charityName}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" gutterBottom>
                      Currency
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" gutterBottom>
                      {userDetails?.donationDetails?.currency}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="subtitle2" gutterBottom>
                      Donation Amount
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" gutterBottom>
                      {userDetails?.donationDetails?.donation_amount}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </>
          ) : null}
          <hr />
          <form className={classes.form}>
            <div className={classes.shipping}>
              <Typography variant="h6" gutterBottom>
                Billing Address
              </Typography>
              <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="firstName"
                    name="firstName"
                    label="First name"
                    fullWidth
                    autoComplete="given-name"
                    value={formValues.firstName || ""}
                    onChange={handleChange}
                    error={!!formErrors.firstName}
                    helperText={formErrors.firstName}
                    autoFocus
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="lastName"
                    name="lastName"
                    label="Last name"
                    value={formValues.lastName || ""}
                    onChange={handleChange}
                    error={!!formErrors.lastName}
                    helperText={formErrors.lastName}
                    fullWidth
                    autoComplete="family-name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="addressLine1"
                    name="addressLine1"
                    label="Address line 1"
                    value={formValues.addressLine1 || ""}
                    onChange={handleChange}
                    error={!!formErrors.addressLine1}
                    helperText={formErrors.addressLine1}
                    fullWidth
                    autoComplete="shipping address-line1"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="addressLine2"
                    name="addressLine2"
                    label="Address line 2"
                    value={formValues.addressLine2 || ""}
                    onChange={handleChange}
                    error={!!formErrors.addressLine2}
                    helperText={formErrors.addressLine2}
                    fullWidth
                    autoComplete="shipping address-line2"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="city"
                    name="city"
                    label="City"
                    value={formValues.city || ""}
                    onChange={handleChange}
                    error={!!formErrors.city}
                    helperText={formErrors.city}
                    fullWidth
                    autoComplete="shipping address-level2"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="state"
                    name="state"
                    label="State/Province/Region"
                    value={formValues.state || ""}
                    onChange={handleChange}
                    error={!!formErrors.state}
                    helperText={formErrors.state}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-helper-label">
                      Country
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={formValues.country || ""}
                      onChange={handleChange}
                      name="country"
                    >
                      <MenuItem value="AU">Australia</MenuItem>
                      <MenuItem value="AT">Austria</MenuItem>
                      <MenuItem value="BE">Belgium</MenuItem>
                      <MenuItem value="BR">Brazil</MenuItem>
                      <MenuItem value="CA">Canada</MenuItem>
                      <MenuItem value="CN">China</MenuItem>
                      <MenuItem value="DK">Denmark</MenuItem>
                      <MenuItem value="FI">Finland</MenuItem>
                      <MenuItem value="FR">France</MenuItem>
                      <MenuItem value="DE">Germany</MenuItem>
                      <MenuItem value="HK">Hong Kong</MenuItem>
                      <MenuItem value="IE">Ireland</MenuItem>
                      <MenuItem value="IT">Italy</MenuItem>
                      <MenuItem value="JP">Japan</MenuItem>
                      <MenuItem value="LU">Luxembourg</MenuItem>
                      <MenuItem value="MY">Malaysia</MenuItem>
                      <MenuItem value="MX">Mexico</MenuItem>
                      <MenuItem value="NL">Netherlands</MenuItem>
                      <MenuItem value="NZ">New Zealand</MenuItem>
                      <MenuItem value="NO">Norway</MenuItem>
                      <MenuItem value="PL">Poland</MenuItem>
                      <MenuItem value="PT">Portugal</MenuItem>
                      <MenuItem value="SG">Singapore</MenuItem>
                      <MenuItem value="ES">Spain</MenuItem>
                      <MenuItem value="SE">Sweden</MenuItem>
                      <MenuItem value="CH">Switzerland</MenuItem>
                      <MenuItem value="GB">United Kingdom</MenuItem>
                      <MenuItem value="US">United States</MenuItem>
                    </Select>
                    {!!formErrors.country && (
                      <FormHelperText>{formErrors.country}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <div className={classes.payment}>
                <Typography variant="h6" gutterBottom>
                  Payment Information
                </Typography>
                <fieldset className="FormGroup">
                  <div className="FormRow">
                    {showDummyCard ? (
                      <DummyCard
                        cardNumber={userBilling.cardInfo.last4}
                        expMonth={userBilling.cardInfo.exp_month}
                        expYear={userBilling.cardInfo.exp_year}
                        toggle={toggleDummyCard}
                      />
                    ) : (
                      <CardElement options={CARD_OPTIONS} />
                    )}
                  </div>
                </fieldset>
                <Grid item xs={12} style={{ paddingLeft: "20px" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="secondary"
                        name="termscheckbox"
                        value={formValues.termscheckbox}
                        onChange={handleChange}
                      />
                    }
                    label="T&C"
                  />
                </Grid>
              </div>
              {!Object.keys(userBilling).length > 0 && (
                <div
                  style={{
                    display: "flex",
                    marginTop: 50,
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    variant="contained"
                    disabled={!isValid}
                    color="primary"
                    onClick={isValid ? handleSubmit : null}
                  >
                    Submit
                  </Button>
                </div>
              )}
              {Object.keys(userBilling).length > 0 && (
                <div
                  style={{
                    display: "flex",
                    marginTop: 50,
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    variant="contained"
                    disabled={!isValid}
                    color="primary"
                    onClick={isValid ? handleUpdate : null}
                  >
                    Update Details
                  </Button>
                </div>
              )}
            </div>
          </form>
        </Paper>
      </Container>
      <CustomSnackbar {...snackbar} />
    </div>
  );
};
