import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import { DonationBoxPreview } from "../../";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: 16,
    minWidth: 270,
  },
  center: {
    justifyContent: "center",
    display: "flex",
  },
}));

// Destructuring props
const CustomizeDonationButton = ({
  handleNext,
  handleBack,
  values: {
    buttonColor,
    buttonShape,
    selectedCharity,
    defaultCurrency,
    donationAmount,
    charityName,
  },
}) => {
  const classes = useStyles();
  // Check if all values are not empty or if there are some error
  const isValid = true;

  return (
    <>
      <Grid container spacing={2} className={classes.center}>
        <Grid item xs={12} sm={6}>
          <DonationBoxPreview
            buttonText={""}
            buttonColor={buttonColor}
            buttonShape={buttonShape}
            currency={defaultCurrency}
            charityName={selectedCharity.charityName || charityName}
            donationAmount={donationAmount}
          />
        </Grid>
      </Grid>
      <div
        style={{ display: "flex", marginTop: 50, justifyContent: "flex-end" }}
      >
        <Button
          variant="contained"
          color="default"
          onClick={handleBack}
          style={{ marginRight: 10 }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          disabled={!isValid}
          color="primary"
          onClick={isValid ? handleNext : null}
        >
          Submit
        </Button>
      </div>
    </>
  );
};

export default CustomizeDonationButton;
