import React from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Shopify from "./shopify.svg";
import WelcomeGraphic from "./welcome-graphic.svg";

import "./Integration.scss";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
    fontSize: "1.25rem",
    fontWeight: 800,
  },
  grid: {
    textAlign: "center",
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

export const Integration = () => {
  const classes = useStyles();
  const history = useHistory();

  const customIntegration = () => {
    history.push("/dashboard/steps");
  };

  const tiers = [
    {
      title: "Give & Grow for Shopify",
      image: Shopify,
      style: {
        height: "2.5rem",
        margin: "1.25rem",
      },
      description:
        "Add donations at checkout, round up for your favorite nonprofit(s) to your Shopify store.",
      buttonText: "Install",
      buttonVariant: "outlined",
    },
    {
      title: "Custom integrations",
      image: WelcomeGraphic,
      style: {
        height: "5rem",
      },
      description:
        "Contact us to build a custom donation experience for your product, event, and more.",
      buttonText: "Access",
      buttonVariant: "outlined",
      click: customIntegration,
    },
  ];

  return (
    <div className="integration">
      <div className={classes.content}>
        <div className={classes.appBarSpacer} />
        <CssBaseline />
        <Container maxWidth="md" component="main">
          <Grid item xs={12} md={8}>
            <Typography variant="button" display="block" gutterBottom>
              Integrations
            </Typography>
          </Grid>
          <Grid container spacing={5} alignItems="flex-end">
            {tiers.map((tier) => (
              // Enterprise card is full width at sm breakpoint
              <Grid
                item
                key={tier.title}
                xs={12}
                sm={12}
                md={4}
                className={classes.grid}
              >
                <Card>
                  <CardContent>
                    <div className="image__container">
                      <img
                        alt={tier.image}
                        style={tier.style}
                        src={tier.image}
                      />
                    </div>
                    <div className="card__title">{tier.title}</div>
                    <Typography variant="subtitle1" align="center">
                      {tier.description}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      fullWidth
                      variant={tier.buttonVariant}
                      color="primary"
                      onClick={tier.click}
                    >
                      {tier.buttonText}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </div>
    </div>
  );
};
