import React, { useEffect, useState } from "react";
import { UserDetails } from "./UserDetails";
import { useDispatch } from "react-redux";
import {
  getAllUsers,
  approveUserById,
  pauseUserAccount,
} from "../../../actions/auth";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import CustomSnackbar from "../../Shared/components/CustomSnackbar";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  grow: {
    flexGrow: 1,
  },
}));

export const ApproveUsers = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [unApprovedUsers, setUnapprovedUsers] = useState([]);
  const [messageObj, setMessageObj] = useState({ status: "", message: "" });
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    isError: "",
    message: "",
    handleClose: () => closeSnackbar(),
  });

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar((preVal) => ({
      ...preVal,
      isOpen: false,
    }));
  };

  useEffect(() => {
    dispatch(
      getAllUsers((usersData) =>
        setUnapprovedUsers((prevValue) => (prevValue = usersData))
      )
    );
    return () => {};
  }, [messageObj]);

  const handleApproveUser = (row, operation) => {
    if (row._id) {
      const id = row._id;
      if (row.isFirstTime) {
        dispatch(
          approveUserById(id, operation, (res) => {
            const { message, status } = res;
            setSnackbar((preVal) => ({
              ...preVal,
              isOpen: true,
              isError: status,
              message: message,
            }));

            if (status == "success") {
              setMessageObj({ message, status });
            }
          })
        );
      } else {
        if (!row.isPaused) {
          dispatch(
            pauseUserAccount(id, "pauseAccounnt", (res) => {
              const { message, status } = res;
              setSnackbar((preVal) => ({
                ...preVal,
                isOpen: true,
                isError: status,
                message: message,
              }));
              if (status == "success") {
                setMessageObj({ message, status });
              }
            })
          );
        } else {
          dispatch(
            pauseUserAccount(id, "unpauseAccounnt", (res) => {
              const { message, status } = res;
              setSnackbar((preVal) => ({
                ...preVal,
                isOpen: true,
                isError: status,
                message: message,
              }));
              if (status == "success") {
                setMessageObj({ message, status });
              }
            })
          );
        }
      }
    }
  };

  return (
    <div className="approve-users">
      <div className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <UserDetails
                rows={unApprovedUsers}
                handleApproveUser={handleApproveUser}
              />
            </Grid>
          </Grid>
        </Container>
        <CustomSnackbar {...snackbar} />
      </div>
    </div>
  );
};
