import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";

import { EcommerceSection, ShopifySection } from "../";

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginTop: "2rem",
    marginBottom: "2rem",
    borderBottom: `1px solid rgba(0, 0, 0, 0.12)`,
  },
}));

const Divider = () => {
  const classes = useStyles();
  return <hr className={classes.divider} />;
};

export const Home = () => {
  return (
    <div className="home">
      <CssBaseline />
      <main>
        <EcommerceSection />
        <Divider />
        <ShopifySection />
      </main>
    </div>
  );
};
