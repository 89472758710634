import React from "react";
import { Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import currencyList from "../../../Shared/utilities/currencyList";

const useStyle = makeStyles(() => {
  return {
    formControl: {
      marginTop: 16,
      minWidth: 270,
    },
    flexCenter: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: 10,
    },
  };
});

const SecondStep = ({
  handleChange,
  values: { defaultCurrency, donationAmount },
  handleNext,
  handleBack,
}) => {
  const classes = useStyle();

  const isValid =
    defaultCurrency &&
    defaultCurrency.name.length > 0 &&
    donationAmount > 0 &&
    donationAmount <= 5;

  console.log(defaultCurrency, "defaultCurrency");
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-controlled-open-select-label">
            Default Currency
          </InputLabel>
          <Select
            labelId="demo-controlled-open-select-label-1"
            fullWidth
            name="defaultCurrency"
            value={defaultCurrency || ""}
            onChange={handleChange}
          >
            {currencyList.map((currency, index) => (
              <MenuItem key={index} value={currency}>
                {currency.name + " (" + currency.symbol + ")"}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-controlled-open-select-label-2">
            Select Amount
          </InputLabel>
          <Select
            labelId="demo-controlled-open-select-label"
            fullWidth
            name="donationAmount"
            value={donationAmount || ""}
            onChange={handleChange}
          >
            {donationAmountList.map((amount, index) => (
              <MenuItem key={index} value={amount}>
                {defaultCurrency.symbol + amount}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} className={classes.flexCenter}>
        <Button
          variant="contained"
          color="default"
          onClick={handleBack}
          style={{ marginRight: 10 }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          disabled={!isValid}
          color="primary"
          onClick={isValid ? handleNext : null}
          //   onClick={() => console.log(defaultCurrency)}
        >
          Next
        </Button>
      </Grid>
    </Grid>
  );
};

const donationAmountList = [1, 2, 3, 4, 5];

export default SecondStep;
