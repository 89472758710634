import axios from "axios";
import { GET_TRANSACTIONS, MESSAGE } from "../types";
import { ADMIN, SERVER_URL } from "../../constants";

const attachHeaders = () => {
  const token = localStorage.getItem("token");
  const header = { authorization: token };
  return header;
};

export const getTransactions =
  (authRole, dateRange, callback) => async (dispatch) => {
    try {
      const header = attachHeaders();
      let query = ``;
      if (authRole === ADMIN) {
        query = `${SERVER_URL}donation?`;
      } else {
        query = `${SERVER_URL}donation?id=${localStorage.getItem("userId")}&`;
      }
      if (dateRange) {
        const { startDate, endDate } = dateRange;
        if (startDate) {
          query += `startDate=${startDate}`;
        }
        if (endDate) {
          query += `&endDate=${endDate}`;
        }
      }
      const { data } = await axios.get(query, {
        headers: header,
      });

      const { response, status } = data;

      if (status === "success") {
        callback(response || []);
      }
    } catch (e) {
      dispatch({
        type: MESSAGE,
        payload: {
          status: "error",
          message: "Oops ! Please try again later",
        },
      });
    }
  };

export const getWinners = (callback) => async (dispatch) => {
  try {
    const header = attachHeaders();
    const query = `${SERVER_URL}donation/winners`;
    const { data } = await axios.get(query, {
      headers: header,
    });

    const { response, status } = data;
    callback(response);
  } catch (e) {
    dispatch({
      type: MESSAGE,
      payload: {
        status: "error",
        message: "Oops ! Please try again later",
      },
    });
  }
};

export const getDonationByCharity =
  (charityId, showTransctions, callback) => async (dispatch) => {
    try {
      const header = attachHeaders();
      let query = `${SERVER_URL}donation/charity?charityId=${charityId}`;
      if (showTransctions) {
        query += `&showTransactions=true`;
      }
      const { data } = await axios.get(query, {
        headers: header,
      });

      const { response, status } = data;
      if (showTransctions) {
        callback(response || []);
      } else {
        callback(response[0] || {});
      }
    } catch (e) {
      dispatch({
        type: MESSAGE,
        payload: {
          status: "error",
          message: "Oops ! Please try again later",
        },
      });
    }
  };
