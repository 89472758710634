import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionActions from "@material-ui/core/AccordionActions";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TextField from "@material-ui/core/TextField";
import { getEmails, saveEmail } from "../../../actions/emails";
import CustomSnackbar from "../../Shared/components/CustomSnackbar";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "40px",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableHead: {
    textAlign: "center",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export const Notifications = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [emails, setEmails] = React.useState([]);
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    isError: "",
    message: "",
    handleClose: () => closeSnackbar(),
  });

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar((preVal) => ({
      ...preVal,
      isOpen: false,
    }));
  };

  useEffect(() => {
    dispatch(
      getEmails((response) => {
        setEmails(response);
      })
    );
  }, [dispatch]);

  // Handle form change
  const handleChange = (e) => {
    const { name } = e.target;
  };

  const saveTemplate = (email) => {
    dispatch(
      saveEmail(email, ({ status, message }) => {
        setSnackbar((preVal) => ({
          ...preVal,
          isOpen: true,
          isError: status,
          message: message,
        }));
      })
    );
  };

  return (
    <div className="notifications">
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className={classes.root}>
              <Paper className={classes.paper}>
                <Toolbar className={clsx(classes.root)}>
                  <Typography
                    className={classes.title}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                  >
                    Email Templates
                  </Typography>
                </Toolbar>
                <Container maxWidth="lg" className={classes.container}>
                  {emails.map((email) => (
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.heading}>
                          {email.template}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <TextField
                          value={email.html || ""}
                          multiline
                          rows={5}
                          name={email.template}
                          onChange={handleChange}
                          fullWidth
                        />
                      </AccordionDetails>
                      <AccordionActions>
                        <Button
                          size="small"
                          color="primary"
                          onClick={() => saveTemplate(email)}
                        >
                          Save
                        </Button>
                      </AccordionActions>
                    </Accordion>
                  ))}
                </Container>
              </Paper>
            </div>
          </Grid>
        </Grid>
      </Container>
      <CustomSnackbar {...snackbar} />
    </div>
  );
};
