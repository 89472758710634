import axios from "axios";
import { MESSAGE } from "./types";
import { SERVER_URL } from "../constants";

export const contactUs = (values, callback) => async (dispatch) => {
  try {
    const { data } = await axios.post(`${SERVER_URL}contactUs`, values);

    console.log(data, "data");
    dispatch({
      type: MESSAGE,
      payload: {
        status: "success",
        message: data.response,
      },
    });
    callback();
  } catch (e) {}
};

export const clearMessages = () => {
  return {
    type: MESSAGE,
    payload: {
      status: "",
      message: "",
    },
  };
};
