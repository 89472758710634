export const AUTH_USER = "auth_user";
export const AUTH_ROLE = "auth_role";
export const AUTH_ERROR = "auth_error";
export const CLEAR_ERROR = "clear_error";
export const IS_USER_HAS_BILLING = "IS_USER_HAS_BILLING";
export const IS_USER_HAS_DETAILS = "IS_USER_HAS_DETAILS";
export const MESSAGE = "message";
export const CLEAR_MESSAGE = "clear_message";

export const GET_USER_BILLING = "GET_USER_BILLING";

export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
