import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import * as actions from "../../../actions/auth";

export const SignOut = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.signOut());
  }, [dispatch]);

  return (
    <div className="signOut">
      <div>Sorry to see you again</div>
    </div>
  );
};
