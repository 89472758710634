import React, { useEffect } from "react";
import { useParams } from "react-router";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

export const ActivateAccount = ({
  handleNext,
  handleBack,
  handleChange,
  values,
  formErrors,
  registerUser,
}) => {
  const {
    password,
    confirmPassword,
    administratorName,
    companyTelephone,
    companyMobilephone,
    companyAddress,
    companyTown,
    companyCountry,
    companyPostcode,
  } = values;

  useEffect(() => {
    return () => {};
  }, []);

  const isValid =
    password.length > 0 &&
    !formErrors.password &&
    confirmPassword.length > 0 &&
    !formErrors.confirmPassword &&
    administratorName.length > 0 &&
    !formErrors.administratorName &&
    companyTelephone.length > 0 &&
    !formErrors.companyTelephone;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            fullWidth
            label="Password"
            name="password"
            placeholder="Password"
            type="password"
            value={password || ""}
            onChange={handleChange}
            error={!!formErrors.password}
            helperText={formErrors.password}
            margin="normal"
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            fullWidth
            label="ConfirmPassword"
            name="confirmPassword"
            placeholder="ConfirmPassword"
            type="password"
            value={confirmPassword || ""}
            onChange={handleChange}
            error={!!formErrors.confirmPassword}
            helperText={formErrors.confirmPassword}
            margin="normal"
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            fullWidth
            label="Administrator Name"
            name="administratorName"
            placeholder="Administrator Name"
            value={administratorName || ""}
            onChange={handleChange}
            margin="normal"
            error={!!formErrors.administratorName}
            helperText={formErrors.administratorName}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            fullWidth
            label="Company Telephone"
            name="companyTelephone"
            placeholder="Company Telephone"
            value={companyTelephone || ""}
            onChange={handleChange}
            error={!!formErrors.companyTelephone}
            helperText={formErrors.companyTelephone}
            margin="normal"
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            fullWidth
            label="Company MobilePhone"
            name="companyMobilephone"
            placeholder="Company MobilePhone"
            value={companyMobilephone || ""}
            onChange={handleChange}
            error={!!formErrors.companyMobilephone}
            helperText={formErrors.companyMobilephone}
            margin="normal"
            required
          />
        </Grid>
      </Grid>
      <div
        style={{ display: "flex", marginTop: 50, justifyContent: "flex-end" }}
      >
        <Button
          variant="contained"
          disabled={!isValid}
          color="primary"
          onClick={isValid ? handleNext : null}
        >
          Next
        </Button>
      </div>
    </>
  );
};
