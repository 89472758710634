import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";

export default function UpdateCharity({
  formValues: { charityName, charityWebsite, charityNumber, charityAddress },
  handleUpdate,
  handleChange,
  handleCancelUpdate,
  formErrors,
}) {
  const isValid =
    charityName.length > 0 &&
    charityWebsite.length > 0 &&
    charityNumber &&
    charityNumber >= 0 &&
    charityAddress;
  return (
    <Grid container style={{ padding: 20 }} spacing={2}>
      <Grid item sm={6}>
        <TextField
          variant="outlined"
          fullWidth
          label="Charity Name"
          name="charityName"
          placeholder="Charity Name"
          margin="normal"
          value={charityName || ""}
          onChange={handleChange}
          error={!!formErrors.charityName}
          helperText={formErrors.charityName}
          required
        />
      </Grid>
      <Grid item sm={6}>
        <TextField
          variant="outlined"
          fullWidth
          label="Website"
          name="charityWebsite"
          placeholder="Charity Website"
          margin="normal"
          value={charityWebsite || ""}
          onChange={handleChange}
          error={!!formErrors.charityWebsite}
          helperText={formErrors.charityWebsite}
          required
        />
      </Grid>
      <Grid item sm={6}>
        <TextField
          variant="outlined"
          fullWidth
          label="Number"
          name="charityNumber"
          placeholder="Charity Number"
          margin="normal"
          value={charityNumber || ""}
          onChange={handleChange}
          error={!!formErrors.charityNumber}
          helperText={formErrors.charityNumber}
          required
        />
      </Grid>
      <Grid item sm={6}>
        <TextField
          variant="outlined"
          fullWidth
          label="Charity Address"
          name="charityAddress"
          placeholder="Charity Address"
          margin="normal"
          value={charityAddress || ""}
          onChange={handleChange}
          error={!!formErrors.charityAddress}
          helperText={formErrors.charityAddress}
          required
        />
      </Grid>
      <Grid item sm={12}>
        <div
          style={{
            display: "flex",
            marginTop: 10,
            marginBottom: 10,
            justifyContent: "right",
          }}
        >
          <Button
            disabled={!isValid}
            variant="contained"
            color="primary"
            onClick={() => handleUpdate()}
          >
            Save
          </Button>
          <Button
            style={{ marginLeft: 20, color: "#fff", backgroundColor: "red" }}
            variant="contained"
            onClick={handleCancelUpdate}
          >
            Cancel
          </Button>
        </div>
      </Grid>
    </Grid>
  );
}
