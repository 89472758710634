import Router from "./app-routing";
import { makeStyles } from "@material-ui/core/styles";

import "./App.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    justifyContent: "space-between",
  },
}));

function App() {
  const classes = useStyles();

  return (
    <div className="app">
      <div className={classes.root}>
        <Router></Router>
      </div>
    </div>
  );
}

export default App;
