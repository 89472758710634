import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import formValidation from "../Shared/utilities/formValidation";
import { contactUs, clearMessages } from "../../actions";
import CustomSnackbar from "../Shared/components/CustomSnackbar";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import ReCAPTCHA from "react-google-recaptcha";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const initialValues = {
  name: "",
  email: "",
  message: "",
};

const fieldsValidation = {
  name: {
    error: "",
    validate: "required",
  },
  email: {
    error: "",
    validate: "email",
  },
  message: {
    error: "",
    validate: "required",
  },
};

export const ContactUs = () => {
  const classes = useStyles();
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    isError: "",
    message: "",
    handleClose: () => closeSnackbar(),
  });
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const dispatch = useDispatch();
  const { message: messageObj } = useSelector((state) => state.message);

  useEffect(() => {
    console.log(messageObj, "messageObj");
    const { status, message } = messageObj;
    if (status && message) {
      setSnackbar((preVal) => ({
        ...preVal,
        isOpen: true,
        isError: status,
        message: message,
      }));

      dispatch(clearMessages());
    }
  }, [messageObj]);

  // Handle form change
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Set values
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));

    // set errors
    const error = formValidation(name, value, fieldsValidation) || "";

    setFormErrors((prev) => ({
      ...prev,
      [name]: error,
    }));
  };

  const isValid =
    formValues.name.length > 0 &&
    !formErrors.name &&
    formValues.email.length > 0 &&
    !formErrors.email &&
    formValues.message.length > 0 &&
    !formErrors.message;

  const submitForm = () => {
    dispatch(
      contactUs(formValues, () => {
        console.log("Submitted!");
        setFormValues(initialValues);
      })
    );
  };

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar((preVal) => ({
      ...preVal,
      isOpen: false,
    }));
  };

  const onChange = (value) => {
    console.log("Captcha value:", value);
  };

  return (
    <div className="contactUs">
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Contact Us
          </Typography>
          <Grid container spacing={2} noValidate>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="Name"
                name="name"
                placeholder="Enter Name"
                margin="normal"
                value={formValues.name || ""}
                onChange={handleChange}
                error={!!formErrors.name}
                helperText={formErrors.name}
                autoFocus
                required
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="Email"
                name="email"
                placeholder="Enter Email"
                margin="normal"
                value={formValues.email || ""}
                onChange={handleChange}
                error={!!formErrors.email}
                helperText={formErrors.email}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                placeholder="Enter Message here..."
                variant="outlined"
                label="Message"
                name="message"
                margin="normal"
                value={formValues.message || ""}
                onChange={handleChange}
                error={!!formErrors.message}
                helperText={formErrors.message}
                multiline
                rows={5}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <ReCAPTCHA sitekey="Your client site key" onChange={onChange} />,
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              margin: "50 0",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              disabled={!isValid}
              color="primary"
              onClick={isValid ? submitForm : null}
            >
              Submit
            </Button>
          </div>
        </div>
      </Container>
      <CustomSnackbar {...snackbar} />
    </div>
  );
};
