import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import LayersIcon from "@material-ui/icons/Layers";

function ListItemLink(props) {
  const { icon, primary, to } = props;
  let { url } = useRouteMatch();

  const CustomLink = React.useMemo(
    () =>
      React.forwardRef((linkProps, ref) => (
        <Link ref={ref} to={`${url}/${to}`} {...linkProps} />
      )),
    [to]
  );

  return (
    <li>
      <ListItem button component={CustomLink}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

export const mainListItems = (
  <>
    <ListItemLink
      button
      component={Link}
      to="transactions"
      icon={<LayersIcon />}
      primary="Transactions"
    ></ListItemLink>
    <ListItemLink
      button
      component={Link}
      to="settings"
      icon={<LayersIcon />}
      primary="Settings"
    ></ListItemLink>
  </>
);

export const AdminMainListItems = (
  <>
    <ListItemLink
      button
      component={Link}
      to="merchants"
      icon={<LayersIcon />}
      primary="Merchants"
    ></ListItemLink>
    <ListItemLink
      button
      component={Link}
      to="transactions"
      icon={<LayersIcon />}
      primary="Transactions"
    ></ListItemLink>
    <ListItemLink
      button
      component={Link}
      to="winners"
      icon={<LayersIcon />}
      primary="Winners"
    ></ListItemLink>
    <ListItemLink
      button
      component={Link}
      to="charities"
      icon={<LayersIcon />}
      primary="Charities"
    ></ListItemLink>
    <ListItemLink
      button
      component={Link}
      to="settings"
      icon={<LayersIcon />}
      primary="Settings"
    ></ListItemLink>
    <ListItemLink
      button
      component={Link}
      to="notifications"
      icon={<LayersIcon />}
      primary="Email Templates"
    ></ListItemLink>
    {/* <ListItemLink
      button
      component={Link}
      to="integration"
      icon={<LayersIcon />}
      primary="Privacy Policy/Terms & Conditions"
    ></ListItemLink> */}
  </>
);

export const secondaryListItems = <div></div>;
