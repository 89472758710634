import React, { useState } from "react";

import CartDetails from "./steps/CartDetails";
import CustomizeDonationButton from "./steps/CustomizeDonationButton";
import GenerateScript from "./steps/GenerateScript";
import formValidation from "../../Shared/utilities/formValidation";
import StripeContainer from "./steps/StripeContainer";

import Stepper from "@material-ui/core/Stepper";
import Paper from "@material-ui/core/Paper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

import "./IntegrationSteps.scss";

const useStyles = makeStyles((theme) => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(900 + theme.spacing(2) * 2)]: {
      width: 900,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
      padding: theme.spacing(3),
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

// Step titles
const labels = [
  "Your Ecomm Cart Page Details",
  "Donation Button Customization",
  "Payment Details",
  "Get Script",
];

const initialValues = {
  cartPageId: "",
  buyNowId: "",
  buttonText: "Donate",
  buttonColor: "red",
  buttonShape: "pill",
  firstName: "",
  lastName: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  // zip: "",
  country: "US",
  termscheckbox: false,
};

const fieldsValidation = {
  cartPageId: {
    error: "",
    validate: "required",
  },
  buyNowId: {
    error: "",
    validate: "required",
  },
  buttonText: {
    error: "",
    validate: "required",
  },
  buttonColor: {
    error: "",
    validate: "required",
  },
  buttonShape: {
    error: "",
    validate: "required",
  },
  firstName: {
    error: "",
    validate: "required",
  },
  lastName: {
    error: "",
    validate: "required",
  },
  addressLine1: {
    error: "",
    validate: "required",
  },
  addressLine2: {},
  city: {
    error: "",
    validate: "required",
  },
  state: {
    error: "",
    validate: "required",
  },
  // zip: {
  //   error: "",
  //   validate: "required",
  // },
  country: {
    error: "",
    validate: "required",
  },
  termscheckbox: {},
};

export const IntegrationSteps = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});

  // Proceed to next step
  const handleNext = () => setActiveStep((prev) => prev + 1);
  // Go back to prev step
  const handleBack = () => setActiveStep((prev) => prev - 1);

  // Handle form change
  const handleChange = (e) => {
    const { name } = e.target;

    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    // Set values
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));

    // set errors
    const error = formValidation(name, value, fieldsValidation) || "";

    setFormErrors((prev) => ({
      ...prev,
      [name]: error,
    }));
  };

  const handleSteps = (step) => {
    switch (step) {
      case 0:
        return (
          <CartDetails
            handleNext={handleNext}
            handleChange={handleChange}
            values={formValues}
            formErrors={formErrors}
          />
        );
      case 1:
        return (
          <CustomizeDonationButton
            handleNext={handleNext}
            handleBack={handleBack}
            handleChange={handleChange}
            values={formValues}
            formErrors={formErrors}
          />
        );
      case 2:
        return (
          <StripeContainer
            handleNext={handleNext}
            handleBack={handleBack}
            handleChange={handleChange}
            values={formValues}
            formErrors={formErrors}
          />
        );
      case 3:
        return (
          <GenerateScript
            handleBack={handleBack}
            handleChange={handleChange}
            values={formValues}
            formErrors={formErrors}
          />
        );
      default:
        break;
    }
  };

  return (
    <div className="integrationSteps">
      <Container component="main">
        <CssBaseline />
        <div className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h5">
              We need few details from you!
            </Typography>
            <>
              {activeStep === labels.length ? (
                // Last Component
                <GenerateScript />
              ) : (
                <>
                  <Stepper activeStep={activeStep} alternativeLabel>
                    {labels.map((label) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                  {handleSteps(activeStep)}
                </>
              )}
            </>
          </Paper>
        </div>
      </Container>
    </div>
  );
};
