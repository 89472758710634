import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import "./DonationBoxPreview.scss";
const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(30),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    backgroundColor: "#F4F7FA",
    border: "1px solid #ccc",
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
      padding: theme.spacing(3),
    },
  },
}));

const buttonShapeMapper = {
  pill: "20px",
  rectangle: "0px",
};
export const DonationBoxPreview = ({
  buttonShape,
  currency,
  charityName,
  donationAmount,
}) => {
  useEffect(() => {
    console.log("form...", charityName);
  }, []);
  const classes = useStyles();
  const MESSAGE = `Give ${currency.symbol}${donationAmount} to ${charityName} and qualify for 100% Cash back on your purchase `;
  return (
    <Container>
      <CssBaseline />
      <Paper className={classes.paper}>
        <Typography
          component="h1"
          variant="h5"
          style={{ marginBottom: "20px" }}
        >
          Customer View
        </Typography>
        <div className="cashback-container">
          <p className="donation-text">{MESSAGE}</p>
          <div className="input-group-text">
            <input
              className="form-check-input"
              type="checkbox"
              aria-label="Donate Amount"
              readOnly
            />
          </div>
        </div>
      </Paper>
    </Container>
  );
};
