import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import * as actions from "../../../actions/auth";
import FirstStep from "./steps/FirstStep";
import { ActivateAccount } from "../ActivateAccount";
import SecondStep from "./steps/SecondStep";
import Success from "./steps/Success";
import CustomSnackbar from "../../Shared/components/CustomSnackbar";
import formValidation from "../../Shared/utilities/formValidation";

import Stepper from "@material-ui/core/Stepper";
import Paper from "@material-ui/core/Paper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
      padding: theme.spacing(3),
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

// Step titles
const labels = [
  "Merchant Details",
  "Account Activation",
  "Company Address Details",
];

const initialValues = {
  companyName: "",
  storeName: "",
  administratorName: "",
  companyEmail: "",
  password: "",
  confirmPassword: "",
  companyWebsite: "",
  companyTelephone: "",
  companyMobilephone: "",
  companyAddress: "",
  companyTown: "",
  companyCountry: "",
  companyPostcode: "",
  productCategory: "",
};

const fieldsValidation = {
  companyName: {
    error: "",
    validate: "required",
  },
  storeName: {
    error: "",
    validate: "required",
  },
  administratorName: {
    error: "",
    validate: "required",
  },
  companyEmail: {
    error: "",
    validate: "email",
  },
  password: {
    error: "",
    validate: "required",
    minLength: 6,
  },
  confirmPassword: {
    error: "",
    validate: "required",
    minLength: 6,
  },
  companyWebsite: {
    error: "",
    validate: "website",
  },
  companyTelephone: {
    error: "",
    validate: "phoneNumber",
  },
  companyMobilephone: {
    error: "",
    validate: "phoneNumber",
  },
  companyAddress: {
    error: "",
    validate: "required",
  },
  companyTown: {
    error: "",
    validate: "required",
  },
  companyCountry: {
    error: "",
    validate: "required",
  },
  companyPostcode: {},
  productCategory: {
    error: "",
    validate: "required",
  },
  otherCategoryName: {
    error: "",
    validate: "",
  },
};

export const SignUp = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const currentURL = useLocation();
  const { message: messageObj } = useSelector((state) => state.message);
  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [userId, setUserId] = useState("");
  const [userToken, setToken] = useState("");

  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    isError: "",
    message: "",
    handleClose: () => closeSnackbar(),
  });

  useEffect(() => {
    console.log(messageObj, "messageObj");
    const { status, message } = messageObj;
    let isActivationPage = currentURL.pathname.search("signUp/activate");
    if (isActivationPage > -1) {
      const { token, userId } = parseUserIdAndToken(currentURL.pathname);
      if (token && userId) {
        setUserId(userId);
        setToken(token);
        setActiveStep(1);
      }
    }
    if (status && message) {
      setSnackbar((preVal) => ({
        ...preVal,
        isOpen: true,
        isError: status,
        message: message,
      }));
      dispatch(actions.clearErrors());
    }
  }, [messageObj]);

  // Proceed to next step
  const handleNext = () => setActiveStep((prev) => prev + 1);
  // Go back to prev step
  const handleBack = () => setActiveStep((prev) => prev - 1);

  // Parse userId and Token
  const parseUserIdAndToken = (url) => {
    let parsedString = url.split("/") || [];
    let token = parsedString[parsedString.length - 1] || "";
    let userId = parsedString[parsedString.length - 2] || "";
    return { token, userId };
  };

  const updateInfo = (values) => {
    let body = {
      _id: userId,
      administratorName: values.administratorName,
      password: values.password,
      companyTelephone: values.companyTelephone,
      companyAddress: values.companyAddress,
      companyTown: values.companyTown,
      companyCountry: values.companyCountry,
      companyPostcode: values.companyPostcode,
    };
    dispatch(
      actions.updateUserInfo(body, userToken, ({ status, message }) => {
        // Show last component or success message
        console.log(status, message);
        if (status == "success") {
          setActiveStep(labels.length);
        }
      })
    );
  };

  // Handle form change
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Set values
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));

    // set errors
    const error = formValidation(name, value, fieldsValidation) || "";

    setFormErrors((prev) => ({
      ...prev,
      [name]: error,
    }));
  };

  const handleSteps = (step) => {
    switch (step) {
      case 0:
        return (
          <FirstStep
            handleNext={handleNext}
            handleChange={handleChange}
            values={formValues}
            formErrors={formErrors}
          />
        );
      case 1:
        return (
          <ActivateAccount
            handleNext={handleNext}
            handleBack={handleBack}
            handleChange={handleChange}
            values={formValues}
            formErrors={formErrors}
          />
        );
      case 2:
        return (
          <SecondStep
            handleNext={handleNext}
            handleBack={handleBack}
            handleChange={handleChange}
            values={formValues}
            formErrors={formErrors}
            updateInfo={updateInfo}
          />
        );
      default:
        break;
    }
  };

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar((preVal) => ({
      ...preVal,
      isOpen: false,
    }));
  };

  return (
    <>
      <div className="signup">
        <Container component="main">
          <CssBaseline />
          <div className={classes.layout}>
            <Paper className={classes.paper}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign up
              </Typography>
              <>
                {activeStep === labels.length ? (
                  // Last Component
                  <Success values={formValues} />
                ) : (
                  <>
                    <Stepper
                      activeStep={activeStep}
                      style={{ margin: "30px 0 15px" }}
                      alternativeLabel
                    >
                      {labels.map((label) => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                    {handleSteps(activeStep)}
                  </>
                )}
              </>
            </Paper>
          </div>
        </Container>
        <CustomSnackbar {...snackbar} />
      </div>
    </>
  );
};
