import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import formValidation from "../../Shared/utilities/formValidation";
import {
  getAdminSettings,
  saveAdminSettings,
  updateAdminSettings,
} from "../../../actions/adminSettings";
import CustomSnackbar from "../../Shared/components/CustomSnackbar";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  grow: {
    flexGrow: 1,
  },
  grid: {
    width: "100%",
    padding: "16px",
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "flex-end",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
}));

const initialValues = {
  transactionWinner: "",
};

const fieldsValidation = {
  transactionWinner: {
    error: "",
    validate: "required",
  },
};

export const AdminSettings = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [snackbar, setSnackbar] = useState({
    isOpen: false,
    isError: "",
    message: "",
    handleClose: () => closeSnackbar(),
  });

  useEffect(() => {
    dispatch(
      getAdminSettings(({ data }) => {
        setFormValues(data);
      })
    );
  }, []);

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar((preVal) => ({
      ...preVal,
      isOpen: false,
    }));
  };

  // Handle form change
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Set values
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));

    // set errors
    const error = formValidation(name, value, fieldsValidation) || "";

    setFormErrors((prev) => ({
      ...prev,
      [name]: error,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(
      saveAdminSettings(formValues, ({ message, status }) => {
        setSnackbar((preVal) => ({
          ...preVal,
          isOpen: true,
          isError: status,
          message: message,
        }));
      })
    );
  };

  const handleUpdate = (e) => {
    e.preventDefault(e);
    dispatch(
      updateAdminSettings(formValues, ({ message, status }) => {
        setSnackbar((preVal) => ({
          ...preVal,
          isOpen: true,
          isError: status,
          message: message,
        }));
      })
    );
  };

  const isValid =
    formValues.transactionWinner &&
    formValues.transactionWinner.length > 0 &&
    !formErrors.transactionWinner;

  console.log(formValues, "formValues");
  return (
    <div className="adminSetting">
      <div className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Grid container spacing={3} className={classes.grid}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" gutterBottom>
                      Cashback Transaction Winner
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      required
                      id="transactionWinner"
                      name="transactionWinner"
                      label="Every Transaction"
                      value={formValues.transactionWinner || ""}
                      onChange={handleChange}
                      error={!!formErrors.transactionWinner}
                      helperText={formErrors.transactionWinner}
                      required
                    />
                  </Grid>
                </Grid>
                <div>
                  {!Object.keys(formValues).length > 0 ? (
                    <div
                      style={{
                        display: "flex",
                        marginTop: 50,
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        variant="contained"
                        disabled={!isValid}
                        color="primary"
                        onClick={isValid ? handleSubmit : null}
                      >
                        Submit
                      </Button>
                    </div>
                  ) : (
                    ""
                  )}
                  {Object.keys(formValues).length > 0 ? (
                    <div
                      style={{
                        display: "flex",
                        marginTop: 50,
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        variant="contained"
                        disabled={!isValid}
                        color="primary"
                        onClick={isValid ? handleUpdate : null}
                      >
                        Update Details
                      </Button>
                    </div>
                  ) : (
                    " "
                  )}
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div>
      <CustomSnackbar {...snackbar} />
    </div>
  );
};
