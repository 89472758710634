import axios from "axios";
import { MESSAGE } from "../types";
import { SERVER_URL } from "../../constants";

const attachHeaders = () => {
  const token = localStorage.getItem("token");
  const header = { authorization: token };
  return header;
};

export const getEmails = (callback) => async (dispatch) => {
  try {
    const header = attachHeaders();
    const query = `${SERVER_URL}email`;
    const { data } = await axios.get(query, {
      headers: header,
    });

    const { response, status } = data;
    callback(response);
  } catch (e) {
    dispatch({
      type: MESSAGE,
      payload: {
        status: "error",
        message: "Oops ! Please try again later",
      },
    });
  }
};

export const saveEmail = (email, callback) => async (dispatch) => {
  try {
    const header = attachHeaders();
    const query = `${SERVER_URL}email`;
    const { data } = await axios.post(query, email, {
      headers: header,
    });

    const { response, status } = data;
    callback({
      status,
      message: "Email Template has been saved!",
    });
  } catch (e) {
    dispatch({
      type: MESSAGE,
      payload: {
        status: "error",
        message: "Oops ! Please try again later",
      },
    });
  }
};
