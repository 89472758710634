import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { STRIPE_PUBLIC_KEY } from "../../../../constants";
import { UserProfile } from "../..";

const stripeTestPromise = loadStripe(STRIPE_PUBLIC_KEY);

const StripeContainer = (props) => {
  return (
    <Elements stripe={stripeTestPromise}>
      <UserProfile {...props} />
    </Elements>
  );
};

export default StripeContainer;
