import axios from "axios";
import { GET_USER_BILLING, MESSAGE, IS_USER_HAS_BILLING } from "../types";
import { SERVER_URL } from "../../constants";

const attachHeaders = () => {
  const token = localStorage.getItem("token");
  const header = { authorization: token };
  return header;
};

export const addUserBilling = (values, callback) => async (dispatch) => {
  try {
    const header = attachHeaders();
    values.userId = localStorage.getItem("userId");
    const { data } = await axios.post(`${SERVER_URL}userBilling`, values, {
      headers: header,
    });
    const { status } = data;

    dispatch({
      type: IS_USER_HAS_BILLING,
      payload: "true",
    });

    localStorage.setItem("isUserHasBilling", "true");
    dispatch({
      type: MESSAGE,
      payload: {
        status,
        message: "User Billing Details has been added successfully!",
      },
    });
    callback();
  } catch (e) {
    dispatch({
      type: MESSAGE,
      payload: {
        status: "error",
        message: "Oops ! Please try again later",
      },
    });
  }
};

export const getUserBilling = () => async (dispatch) => {
  try {
    const header = attachHeaders();
    const { data } = await axios.get(
      `${SERVER_URL}userBilling?id=${localStorage.getItem("userId")}`,
      {
        headers: header,
      }
    );
    const { response } = data;
    dispatch({
      type: GET_USER_BILLING,
      payload: response,
    });
  } catch (e) {
    dispatch({
      type: MESSAGE,
      payload: {
        status: "error",
        message: "Oops ! Please try again later",
      },
    });
  }
};

export const updateUserBilling = (values) => async (dispatch) => {
  try {
    const header = attachHeaders();
    values.userId = localStorage.getItem("userId");
    const { data } = await axios.put(
      `${SERVER_URL}userBilling?id=${localStorage.getItem("userId")}`,
      values,
      {
        headers: header,
      }
    );
    const { status, response } = data;
    dispatch({
      type: MESSAGE,
      payload: {
        status,
        message: "User Billing Details has been Updated successfully!",
      },
    });
  } catch (e) {
    dispatch({
      type: MESSAGE,
      payload: {
        status: "error",
        message: "Oops ! Please try again later",
      },
    });
  }
};
