import axios from "axios";
import {
  AUTH_USER,
  AUTH_ROLE,
  AUTH_ERROR,
  CLEAR_ERROR,
  IS_USER_HAS_BILLING,
  IS_USER_HAS_DETAILS,
  MESSAGE,
} from "../types";
import { SERVER_URL } from "../../constants";

export const signUp = (values, callback) => async (dispatch) => {
  try {
    const { data } = await axios.post(`${SERVER_URL}user/signUp`, values);

    const { status, message } = checkResponseStatus(data, "signup");
    dispatch({
      type: MESSAGE,
      payload: {
        status,
        message,
      },
    });
    callback(status, message);
  } catch (e) {
    dispatch({
      type: MESSAGE,
      payload: {
        status: "error",
        message: "Email is in use",
      },
    });
  }
};

export const signIn = (userForm, callback) => async (dispatch) => {
  try {
    const { data } = await axios.post(`${SERVER_URL}user/login`, userForm);

    console.log(data, "data");
    const { response, status } = data;
    if (status === "success") {
      dispatch({
        type: AUTH_USER,
        payload: response.token.token,
      });
      dispatch({
        type: AUTH_ROLE,
        payload: response.role,
      });
      dispatch({
        type: IS_USER_HAS_BILLING,
        payload: response.isUserHasBilling,
      });
      dispatch({
        type: IS_USER_HAS_DETAILS,
        payload: response.isUserhasDetails,
      });
      dispatch({
        type: MESSAGE,
        payload: {
          status: "success",
          message: "You have successfully Logged In!",
        },
      });
      localStorage.setItem("token", response.token.token);
      localStorage.setItem("role", response.role);
      localStorage.setItem("userId", response._id);
      localStorage.setItem("isUserHasBilling", response.isUserHasBilling);
      localStorage.setItem("isUserhasDetails", response.isUserhasDetails);
      callback();
    }
  } catch (e) {
    dispatch({
      type: AUTH_ERROR,
      payload: "Invalid login Credentails",
    });
  }
};

export const signOut = () => {
  localStorage.removeItem("token");

  return {
    type: AUTH_USER,
    payload: "",
  };
};

export const forgotPassword = (userForm, callback) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${SERVER_URL}user/forgotPassword`,
      userForm
    );
    const { status, message } = checkResponseStatus(data);

    dispatch({
      type: MESSAGE,
      payload: {
        status,
        message,
      },
    });

    callback();
  } catch ({ message }) {
    dispatch({
      type: MESSAGE,
      payload: {
        status: "error",
        message,
      },
    });
  }
};

export const resetPassword =
  ({ id, token }, callback) =>
  async (dispatch) => {
    try {
      const { data } = await axios.get(
        `${SERVER_URL}user/resetPassword/${id}/${token}`
      );

      const { status, message } = checkResponseStatus(data);

      dispatch({
        type: MESSAGE,
        payload: {
          status,
          message,
        },
      });
    } catch ({ message }) {
      // dispatch({
      //   type: MESSAGE,
      //   payload: {
      //     status: "error",
      //     message,
      //   },
      // });
      callback();
    }
  };

export const resetUserPassword =
  (userForm, { id, token }, callback) =>
  async (dispatch) => {
    try {
      const header = { authorization: token };
      const { data } = await axios.post(
        `${SERVER_URL}user/resetPassword/${id}/${token}`,
        userForm,
        { headers: header }
      );

      const { status, message } = checkResponseStatus(data);

      dispatch({
        type: MESSAGE,
        payload: {
          status,
          message,
        },
      });

      callback();
    } catch ({ message }) {
      dispatch({
        type: MESSAGE,
        payload: {
          status: "error",
          message,
        },
      });
    }
  };

export const clearErrors = () => {
  return {
    type: CLEAR_ERROR,
    payload: "",
  };
};

export const getAllUsers = (callback) => async (dispatch) => {
  try {
    const token = localStorage.getItem("token");
    const header = { authorization: token };
    const { data } = await axios.get(`${SERVER_URL}user/`, { headers: header });
    const { response, status } = data;
    if (status === "success") {
      callback(response || []);
    }
    //
  } catch (e) {
    dispatch({
      type: AUTH_ERROR,
      payload: "Error in fetching users",
    });
  }
};

export const approveUserById =
  (id, operation, callback) => async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const header = { authorization: token };
      const body = {
        isApproved: true,
      };
      const { data } = await axios.put(
        `${SERVER_URL}user/${operation}/${id}`,
        body,
        { headers: header }
      );
      const { message, status } = checkResponseStatus(data);
      if (status === "success") {
        callback({ message, status });
      }
    } catch (e) {
      const { message, status } = checkResponseStatus(e);
      callback({ message, status });
    }
  };

export const pauseUserAccount =
  (id, operation, callback) => async (dispatch) => {
    try {
      const token = localStorage.getItem("token");
      const header = { authorization: token };
      const body = {
        isPaused: true,
      };
      const { data } = await axios.put(
        `${SERVER_URL}user/${operation}/${id}`,
        body,
        { headers: header }
      );
      const { message, status } = checkResponseStatus(data);
      if (status === "success") {
        callback({ message, status });
      }
    } catch (e) {
      const { message, status } = checkResponseStatus(e);
      callback({ message, status });
    }
  };

export const updateUserInfo = (body, token, callback) => async (dispatch) => {
  try {
    const header = { authorization: token };
    const { data } = await axios.put(
      `${SERVER_URL}user/createUserPassword`,
      body,
      { headers: header }
    );
    const { message, status } = checkResponseStatus(data);
    if (status === "success") {
      callback({ message, status });
    }
  } catch (e) {
    console.log(e);
    const { message, status } = checkResponseStatus(e);
    if (!message || !status) {
      message = "Something went wrong !";
      status = status || "error";
    }
    callback({ message, status });
  }
};
const checkResponseStatus = (data, api) => {
  let status = "success";
  let message = "";
  if (data.message || data.response) {
    status = "success";
    message = data.message || data.response || "Success";
    if (api === "signup") {
      message = "Success";
    }
  } else {
    status = "error";
    message = data.error;
  }

  return {
    status,
    message,
  };
};
